import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hook";
import { apiUserLogin, checkUserEmail,apiUpdateLastLoginTime } from "../redux/features/user/user.slice";
import Auti_logo from "../assets/images/Auti_logo.png";
import sent_success from "../assets/images/sent_success.png";
import Google_Icon from '../assets/images/google_icon.png'
import clever_login from '../assets/images/clever_login.png';
import side_img from "../assets/images/children_img.svg";
import "./LoginScreen.css";
import GoogleLogin from "../containers/GoogleLogin"
import { Checkbox } from "@mui/material";

import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import FormControlLabel from "@mui/material/FormControlLabel";

import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import PersonIcon from "@mui/icons-material/Person";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";

import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import CleverLogin from "../containers/CleverLogin";
import { GoogleOAuthProvider } from "@react-oauth/google";
import SingleTeacherSignupModal from "./SingleTeacherSignUpModel";
import MailValidationModal from "./MailValidationModal";
import { useHistory } from "react-router-dom";
import Loading from "./Loading";
import ForgotPasswordModal from "./ForgotPasswordModal";
import { getItem } from "../store/storage";

// import {makeStyles} from 

function Copyright(props: any) {
  return (
    <Typography paragraph color="text.secondary" align="center" {...props}>
      © 2023 Aauti All Rights Reserved.
    </Typography>
  );
}


const theme = createTheme({
  palette: {
    primary: {
      light: "#5283d1",
      main: "#3166ba",
      dark: "#143e82",
      contrastText: "#fff",
    },
  },
  components: {

    MuiTypography: {
      variants: [
        {
          props: { paragraph: true },
          style: {
            color: " #6c7484",
          },
        },
      ],
    },
    MuiGrid: {
      variants: [
        {
          props: { rowSpacing: 1 },
          style: {
            borderTopLeftRadius: 8,
            borderBottomLeftRadius: 8,
            backgroundImage: `url(${side_img})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            '@media screen and (max-width: 550px)':{
              borderTopLeftRadius: 8, // Change or remove the radius for smaller screens
              borderBottomLeftRadius: 8,
            }
          },
        },
        {
          props: { rowSpacing: 2 },
          style: {
            borderTopRightRadius: 8,
            borderBottomRightRadius: 8,
            '@media screen and (max-width: 550px)':{
              borderTopLeftRadius: 8, // Change or remove the radius for smaller screens
              borderBottomLeftRadius: 8,
            }
            // height: "65vh",
          },
        },
      ],
    },
  },
});

export default function LoginScreen(props: any) {

  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [errMsg, setErrMsg] = useState<string>("");
  const [showErrMsg, setShowErrMsg] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<any>(false);
  const [forgotPassword, setForgotPassword] = useState<any>(false)
  const [showSentMsg, setShowSentMsg] = useState<any>(false)
  const [email, setEmail] = useState<any>("")
  const [focus, setFocus] = useState<any>(false)
  const validEmailMsg = "Enter Valid Email"
  const [isChecked, setIscheked] = useState<any>(false)
  const [openSignupModel, setOpenSignUpModel] = useState(false)
  const [showValidationModal,setShowValidationModal]=useState<any>(false)
  const [showForgotPasswordModal,setShowForgotPasswordModal]=useState<any>(false)
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


  console.log('isSignUpOpen  ', props.isSignUpOpen)
  const [loginWithClasscode, setLoginWithClasscode] = useState(false)
  const dispatch = useAppDispatch();
  const history = useHistory()
  const user = useAppSelector((state: any) => state.user)
  const sendChangePasswordEmailDetails = useAppSelector(
    (state: any) => state.user.sendChangePasswordErrorDetails

  )
  const userData = useState(getItem("userDetails"))


  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {

    event.preventDefault();
    if (username === "" || password === "") {
      setErrMsg("Fields shouldn't be empty");
      setShowErrMsg(true);
    } else {
      setErrMsg("");
      setShowErrMsg(false);
      if(loginWithClasscode) {
        history.push(`/student-singup?userId=${username}&classCode=${password}`)
      } else {
        dispatch(apiUserLogin({ email: username?.toLowerCase(), password }))
        dispatch(apiUpdateLastLoginTime({email:username?.toLowerCase()}))
      }
    }
  };


  const onPressEnter = (event: any) => {
    if (event.key === "Enter") {
      handleSubmit(event)
    }

  }


  useEffect(() => {
    if (user.status === "failed") {
      setErrMsg(user.error)
      setShowErrMsg(true)
    }

    if (user.cleverLoginData.status === "failed") {
      setErrMsg(user.cleverLoginData.error)
      setShowErrMsg(true)
    }

  }, [user.status, user.cleverLoginData.status])

  useEffect(() => {
    console.log(sendChangePasswordEmailDetails,"sendChangePasswordEmailDetailssss")
    console.log(sendChangePasswordEmailDetails,"sendChangePasswordEmailDetailssss")
    if (sendChangePasswordEmailDetails?.status === "succeeded") {
      console.log(sendChangePasswordEmailDetails,"suceededdd")
      setShowForgotPasswordModal(true)
      setShowErrMsg(false)
    }
    else if (sendChangePasswordEmailDetails.status === "failed") {
      setShowForgotPasswordModal(false)
      setErrMsg(sendChangePasswordEmailDetails.error)
      setShowErrMsg(true)
    }
  }, [sendChangePasswordEmailDetails])

  // const Loading = () => {
  //   return(

  //   <div className="w-100 d-flex justify-content-center align-items-center" 
  //     style={{
  //       backgroundColor:"rgba(250,250,250,0.6)",position:"absolute",height:"70vh",zIndex:1
  //     }}
  //   >
  //     <div className="spinner-border text-primary ">
  //     </div>
  //   </div>
  //   )
  //   }

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const renderSentEmailSection = () => {

    const backToHome =()=>{
          setForgotPassword(false)
          setShowSentMsg(false)
          setEmail("")
    }

    return (

      <Box sx={{ mb: "2%", mt: "7%", display: "flex", flexDirection: "column", alignItems: "center" }}>
        <img src={sent_success} alt="logo" style={{ height: '50%', width: "80%", marginBottom: "13px" }} />
        {/* <p >{sendChangePasswordEmailDetails.message}</p> */}
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", mt: "3%", border: '1px solid #edf2ef', paddingLeft: 1, width: "100%" }}>
          <Typography>Back to </Typography>
          <Grid>

            <Button color={"primary"} onClick={() =>backToHome()} type="button">Login</Button>
          </Grid>
        </Box>
      </Box>
    )
  }

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const renderForgotPasswordSection = () => {
    const emailChanged = (e: any) => {
      setEmail(e.target.value)
    }

    const sendMail = () => {
      if (!email) {
        setErrMsg("Empty field")
        setShowErrMsg(true)
      }
      // if (user.sendChangePasswordErrorDetails.status !== "loading") {

        else if (re.test(email)) {
          setErrMsg("")
          setShowErrMsg(false)

          const baseUrl = window.location.href + 'change-password'

          dispatch(checkUserEmail({ email}))
        }
        else {
          setErrMsg("enter valid email")
          setShowErrMsg(true)
        // }
      }
    }
    

    const onPressSubmit = (event: any) => {

      if (event.key === "Enter") {
        sendMail()
      }
    }

    // useEffect(()=>{
    //   if(sendChangePasswordEmailDetails?.status=="succeeded"){
    //     setShowForgotPasswordModal(true)
    //   }
    // },[sendChangePasswordEmailDetails])

    

    return (
      <Grid container={true}>

        <Box sx={{
          pt: "3%",
          px: "13%",
          mb: "4%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          // height: "100%",
        }}>
          <Box sx={{ mt: "3%", width: "100%" }}
          //  component="form"
          // noValidate onSubmit={sendMail}
          >


            <Typography
              variant="h5"
              sx={{
                fontFamily: "sans-serif",

                fontWeight: "500",
                color: " #101626", fontSize: "22px", mb: "4%"
              }}
            >
              Forgot Password
            </Typography>
            <Typography sx={{ fontFamily: "sans-serif", color: "#6c7484", fontSize: 12 }}>Don't worry! It happens.</Typography>
            <Typography sx={{ fontFamily: "sans-serif", color: "#6c7484", fontSize: 12, mt: "2%" }}>Please enter the email address associated with your account.</Typography>
            <Box>
              <Typography sx={{ fontFamily: "sans-serif", color: "#333333", fontSize: 13, mt: "4%", mb: "2%" }}>Email</Typography>
              <OutlinedInput required
                size="small"
                fullWidth
                autoFocus
                placeholder="johndoe@xyz.com"
                value={email}
                onChange={emailChanged} onKeyDown={onPressSubmit}
              />
              <Grid sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", mt: "6%", mb: "2%" }}>

                <Button color="primary" variant="contained" fullWidth type="submit" onClick={sendMail} sx= {{textTransform:"capitalize"}}>
                  {/* {sendChangePasswordEmailDetails.status === "loading" ? "Submitting..." : "submit"} */}
                  Submit
                </Button>
              </Grid>
            </Box>
          </Box>
          <Box sx={{ display: "flex", mt: "4%", border: '1px solid #edf2ef', paddingLeft: 1, alignItems: 'center' }}>
            <Typography style={{ fontSize: "12px" }}>Back to </Typography>
            <Button style={{ fontSize: "12px",textTransform:"capitalize" }} color={"primary"} onClick={() => setForgotPassword(false)} type="button">
              Login
            </Button>
            <Typography style={{ fontSize: "12px" }}>Don't have an account ?</Typography>
            <Button style={{ fontSize: "12px", textTransform:"capitalize"}} onClick={()=>setOpenSignUpModel(true)}>SignUp</Button>
          </Box>


        </Box>
      </Grid>
    )


  }

  const clientId: any = process.env.REACT_APP_GOOGLE_CLIENT_ID

  const renderLoginSection = (isLoginWithClasscode?: boolean) => {
    const forgotPassError = () => {
      setForgotPassword(true)
      setErrMsg("")
      setShowErrMsg(false)
    }
    const onChangCheck = () => {
      setIscheked(!isChecked)
    }

    const credential1 = isLoginWithClasscode ? 'Userid' : 'Username';
    const cred1PlaceholderText = isLoginWithClasscode ? 'Enter your userid' : 'Enter your username';
    const credential2 = isLoginWithClasscode ? 'ClassCode' : 'Password';
    const cred2PlaceholderText = isLoginWithClasscode ? 'Enter your ClassCode' : 'Enter your Password';
    const loginBtnText = isLoginWithClasscode ? 'Login with ClassCode' : 'Login';

    const onClickSignUp = () => {
    
        if(userData?.[0]?.email) {
          history.push(`?otp=yes&email=${userData?.[0]?.email}`)
        } else {
          setOpenSignUpModel(true)
        }
    }

    return (

      <Grid>
        <CssBaseline />
        <Grid>
          <Box
            sx={{
              pt: "3%",
              px: "13%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              position: "relative", mb: "4%",
              '@media screen and (max-width: 558px)': {
                px: "9%",
              },
             
             
            }}
            
          >
            
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: "3%", width: "100%" }}
            >
              <Typography variant="h6" sx={{ color: "#101626", fontSize: 16 }}>
                Welcome back
              </Typography>
              <Typography paragraph sx={{ fontSize: 11 }}>
                 {/* Enter your credentials to access your account. */}
                 {isLoginWithClasscode ? 'Enter your user id and class code to access your account.' : 'Enter your credentials to access your account.'}
              </Typography>
              <Grid>

                <Typography paragraph sx={{ fontSize: 11, mt: 1, mb: "5px" }}>
                {credential1}
                </Typography>

                <OutlinedInput
                  onChange={(d) => setUsername(d.target.value)} className="outLined"
                  value={username}
                  size="small"
                  required
                  fullWidth
                  placeholder={cred1PlaceholderText}
                  autoFocus
                  
                  sx={{ fontSize: 11}}
                  endAdornment={
                    <InputAdornment position="end" className="input-adornment">
                      <PersonIcon fontSize="small" />
                    </InputAdornment>
                  }
                />
                <Typography
                  paragraph
                  mt={2}
                  sx={{ fontSize: 11, mt: 2, mb: "5px" }}
                >
                  {credential2}
                </Typography>
                <OutlinedInput
                  type={showPassword ? "text" : "password"}
                  onChange={(e) => setPassword(e.target.value)}
                  className="outLined"
                  value={password}
                  size="small"
                  required
                  fullWidth
                  placeholder={cred2PlaceholderText}
                  // autoFocus
                  endAdornment={
                    <InputAdornment  position="end"  className="input-adornment">
                      <IconButton
                      className="inputs"
                      sx={{marginRight:"-7px"}}
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword} 
                        onKeyDown={onPressEnter}
                        
                      > 
                        {!showPassword ? (
                          <VisibilityOff fontSize="small"  />
                        ) : (
                          <Visibility fontSize="small" />
                        )}
                      </IconButton> 
                    </InputAdornment>
                  }
                  sx={{ fontSize: 11 }}
                />
              </Grid>
           
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: 0,
                  mt: "3%",
                  mb: "3%"
                }}
              >

             {!isLoginWithClasscode &&
                <Link onClick={() => forgotPassError()}

                  variant="body2"
                  sx={{
                    fontSize: 11,
                    color: "#6c7484",
                    textDecoration: "none",cursor:"pointer"
                  }}
                >
                  Forgot password?
                </Link>
            }
              </Box>

              <Button 
                onClick={() =><Loading/>}
                type="submit"
                fullWidth
                
                variant="contained" disabled={user.status === "loading" || user.cleverLoginData.status==="loading" ||username === "" || password === ""}
                sx={{ fontSize: 14, backgroundColor: "#3166ba", margin: 0, pading: 0,textTransform: 'capitalize' }}
              >  
                {(user.status === "loading" || user.cleverLoginData.status==="loading") ? "Loading...." : loginBtnText}
              </Button>
              <Typography style={{fontSize:"12px",display:"flex",alignItems:"center",justifyContent:"center"}}>
                Don't have an account?
                <Button sx={{fontSize:"14px",textTransform:"none",cursor:"pointer"}} onClick={onClickSignUp}>Sign Up</Button>
              </Typography>
              <Typography sx={{
                fontSize:"12px",
                display:"flex",
                alignItems:"center",
                justifyContent:"center",
                '@media screen and (max-width: 558px)': {
                  fontSize:"12px",
                }
            }}>
                 {!isLoginWithClasscode ? 'Do you have a Class Code?' : ''}
                <Button sx={{fontSize:"14px",textTransform:"none",cursor:"pointer"}} onClick={()=> setLoginWithClasscode(!isLoginWithClasscode)}>
                  {!isLoginWithClasscode ? 'Login with class code' : 'Login with User Name'}
                </Button>
              </Typography>
              {/* No need for beta launch
                
                or
              
              <Box sx={{ justifyContent: 'center', display: 'flex', paddingX: "10px", paddingY: "5px", marginBottom: '10px', alignItems: 'center', borderRadius: "5px", border: "1px solid #f0f2f6" }}>
                <img src={Google_Icon} style={{ height: '24px', width: "24px" }} />
                
                <GoogleOAuthProvider clientId={clientId}>
                  <GoogleLogin setErrMsg={setErrMsg} setUsername={setUsername} setPassword={setPassword} setShowErrMsg={showErrMsg} />
                </GoogleOAuthProvider>
              </Box>
              <CleverLogin props={props} setErrMsg={setErrMsg} setUsername={setUsername} setPassword={setPassword} setShowErrMsg={showErrMsg} />
              */}
            </Box>

              
          </Box>
        </Grid>
      </Grid>

    );
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Grid container={true} sx={
          { width: "90%", height: "76vh", display: "flex", 
          '@media screen and (max-width: 768px)':{
            width:'95%',
            maxWidth:'95%',
          }
        }}
          >
          <CssBaseline />
          <Grid
            rowSpacing={1}
            item
            xs={false}
            sm={6}
            md={6}
            sx={{
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900]
              // height: "75vh",
            }}
          />
          <Grid
            rowSpacing={2}
            item
            xs={12}
            sm={6}
            md={6}
            component={Paper}
            elevation={1}
            square
          >
            <Box
              sx={{
                pt: "3%",
                px: "5%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                // position: "relative",
                height: "100%",
                width: "100%"
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  height: "100%",
                  width: "100%",
                  '@media screen and (min-width: 768px) and (max-width:1024px)': {
                    position: "relative",
                    height: "20%",
                    width: "100%",
                   }
                }}
              >
                {user.status === 'loading' && <Loading/>}
              </Box>
              <Box className="aauti-logo-container" sx={{ mb: "2%", mt: "7%" }}>
                <img
                  src={Auti_logo}
                  alt="Logo"
                  style={{ width: "120px", height: "50px" }}
                />
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "600",
                    fontFamily: "sans-serif",
                    color: "#108ac9",
                  }}
                >
                  School
                </Typography>
              </Box>
              {showSentMsg && forgotPassword !== false ? renderSentEmailSection() : (
                <Grid container={true} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }} >

                  <Grid sx={{ width: "90%" }}
                  >
                    {showErrMsg && (
                      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#ffe4d6" }}>
                        <IconButton sx={{ mb: 1 }}>
                          <ReportProblemIcon fontSize="small" color="warning" />
                        </IconButton >

                        <Typography
                          paragraph
                          mt={1}
                          sx={{ fontSize: 11, color: "#e04e4e", alignSelf: "center", textAlign: "center", p: 1 }}
                        >
                          {errMsg}
                        </Typography>
                      </Box>
                    )}

                    {forgotPassword ? renderForgotPasswordSection() : renderLoginSection(loginWithClasscode)}
                  </Grid>
                </Grid>)}
              <Copyright
                sx={{ fontSize: 10, marginTop: 9 }}
              />
            </Box>
          </Grid>
        </Grid>
      </ThemeProvider>
      <SingleTeacherSignupModal setOpenSignupModal={setOpenSignUpModel} openSignupModal={openSignupModel} errorMessage={errMsg} showErr={showErrMsg} setShowValidationModal={setShowValidationModal} />
      {<MailValidationModal showValidationModal={showValidationModal} setShowValidationModal={setShowValidationModal} />}
      {showForgotPasswordModal && <ForgotPasswordModal showForgotPasswordModal={showForgotPasswordModal} setShowForgotPasswordModal={setShowForgotPasswordModal}/>}
    </>
  )
}
