import "bootstrap/dist/css/bootstrap.min.css";
import "../src/assets/css/style.css"

import {BrowserRouter as Router, Route} from 'react-router-dom'
import MainContainer from './containers/MainContainer';
import { AppProvider } from './store/AppContext'
import CleverComponent from "./components/CleverComponent";
import ClasslinkSSO from "./containers/ClasslinkSSO";
import SignupStudentLogin from "./containers/SignupStudentLogin";
import SignupTeacherLogin from "./containers/SignupTeacherLogin";
import ChangePasswordContainer from "./containers/ChangePasswordContainer";
import { useEffect } from "react";
import { setDeviceOrientation } from "./redux/features/user/user.slice";
import { useAppDispatch } from "./redux/hook";
var qs = require('qs');

// comment
function App() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const handleOrientationChange = (e: any) => {
      console.log(
        "handleOrientation",
        e.matches // true or false based on landscape
      );
      dispatch(setDeviceOrientation({isPortrait: !e.matches}))
    };

    const landscapeMediaQuery = window.matchMedia("(orientation: landscape)");

    landscapeMediaQuery.addEventListener("change", handleOrientationChange);

    // Check orientation initially
    handleOrientationChange(landscapeMediaQuery);

    return () => {
      landscapeMediaQuery.removeEventListener(
        "change",
        handleOrientationChange
      );
    };
  }, []);
  
  return (
    <div className="App">
      <AppProvider>
        <Router>
          <Route exact path="/" render={(props) => <MainContainer {...props}/>} />
          <Route exact path="/classlink-sso" render={(props) => <ClasslinkSSO {...props}/>} />
          <Route exact path="/student-singup" render={(props) => <SignupStudentLogin {...props}/>} />
          <Route exact path="/teacher-signup" render={(props) => <SignupTeacherLogin {...props}/>} />
          <Route exact path='/forgot-password' render={(props)=> <ChangePasswordContainer {...props}/>}/> 
          <Route exact path="/Clever" render={(props) => <CleverComponent {...props} />} />
        </Router>    
      </AppProvider> 
    </div>
  );
}


export default App;
