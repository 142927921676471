import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Modal,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
  Link,
  InputLabel,
} from "@mui/material";
import { ReactComponent as SVGCloseIcon } from "../../src/assets/images/closeIcon.svg";

import { useAppDispatch, useAppSelector } from "../redux/hook";
import {
  apiUserPhoneOtpValidate,
  apiUserPhoneValidate,
  apiUserSignUp,
  getUserEmailFromEmailUuid,
  apiSaveUserActivity,
  apiUserVerified,
  apiCreateContactInHubspot,
  apiGetUserActivityDetails,
  apiSaveContactNotes,
  apiUpdateContactStatus
} from "../redux/features/user/user.slice";
import { apiCheckEmailValidation } from "../redux/features/user/user.slice";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { ReactComponent as SVGAautiLogo } from "../assets/images/aautischoolLogo.svg";
import { ReactComponent as BackIcon } from "../assets/images/back.svg"
import mobile from "../assets/images/mobileimage.png";
import otpImage from "../assets/images/otp.jpg";
import reviewing from "../assets/images/reviewing.png";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import MailValidationModal from "../components/MailValidationModal";
import MixpanelService from '../services/mixpanel.service'
import { MixpanelEvents } from '../config/mixpanel.config'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import OtpInput from 'react-otp-input';
import ReportGmailerrorredRoundedIcon from '@mui/icons-material/ReportGmailerrorredRounded';
import { getItem, saveItem } from "../store/storage";
import { useHistory,useLocation } from "react-router-dom";

import AppContext from "../store/AppContext";
import "./signUpModel.css"
var qs = require("qs");

const modalStyle = {
  position: "absolute",
  top: "5%",
  left: "50%",
  transform: "translate( -50%,0)",
  width: "100%",
  maxWidth: "562px",
  bgcolor: "background.paper",
  borderRadius: "4px",
  boxShadow: 24,

  '@media screen and (min-width:360px) and (max-width: 399px)': { 
    height:"95vh"
    },
    '@media screen and (min-width:390px) and (max-width: 399px)': { 
      height:"80vh"
      },
    '@media screen and (min-width: 400px) and (max-width:450px)': { 
     height:"85vh"
    },
    '@media screen and (min-width: 460px) and (max-width:839px)': { 
      height:"63vh",
      top: "10%",
     },
    '@media screen and (min-width: 820px) and (max-width:849px)': { 
     height:"52vh",
     top: "10%",
    },
    '@media screen and (min-width: 850px) and (max-width:1026px)': { 
     height:"52vh"
    },
    '@media screen and (min-width: 850px) and (max-width:855px)': { 
     height:"68vh"
    },
  




};
const modalStyle4 = {
  position: "absolute",
  top: "5%",
  left: "50%",
  transform: "translate( -50%,0)",
  width: "100%",
  maxWidth: "562px",
  bgcolor: "background.paper",
  borderRadius: "4px",
  boxShadow: 24,
  
  '@media screen and (min-width:360px) and (max-width: 399px)': { 
    height:"85vh"
    },
    '@media screen and (min-width:390px) and (max-width: 399px)': { 
      height:"75vh"
      },
    '@media screen and (min-width: 400px) and (max-width:450px)': { 
     height:"70vh"
    },
    '@media screen and (min-width: 460px) and (max-width:750px)': { 
      height:"60vh",
      top: "10%",
     },
      '@media screen and (min-width: 760px) and (max-width:819px)': { 
      height:"60vh"
     },
    '@media screen and (min-width: 820px) and (max-width:849px)': { 
     height:"48vh",
     top: "13%",
    },
    '@media screen and (min-width: 850px) and (max-width:1026px)': { 
     height:"52vh"
    },
    '@media screen and (min-width: 850px) and (max-width:855px)': { 
     height:"68vh"
    },
   
  


};

const modalStyle3 = {
  position: "absolute",
  top: "5%",
  left: "50%",
  transform: "translate( -50%,0)",
  width: "100%",
  maxWidth: "562px",
  bgcolor: "background.paper",
  borderRadius: "4px",
  boxShadow: 24,
  '@media screen and (min-width:360px) and (max-width: 399px)': { 
    height:"70vh"
    },
    '@media screen and (min-width: 400px) and (max-width:450px)': { 
      height:"55vh"
     },
     '@media screen and (min-width: 820px) and (max-width:849px)': { 
      height:"45vh",
      top: "10%",
     },
     '@media screen and (min-width: 850px) and (max-width:855px)': { 
      height:"80vh"
     },
     '@media screen and (min-width: 1024px) and (max-width:1366px)': { 
      height:"40vh"
     },
    //  '@media screen and (min-width: 768px) and (max-width:828px)': { 
    //   height:"50vh"
    //  },
}

const textFieldStyles = {
  fontSize: "10px",
  fontFamily: "HelveticaNeue-Medium",
  color: "rgba(167, 167, 167, 0.8)",
  marginTop: "3px",
  ':placeholder': {
    backgroundColor: "red"
  }
 
}
const modalStyle1 = {
  position: "absolute",
  top: "3%",
  left: "50%",
  transform: "translate( -50%,0)",
  width: "100%",
  maxWidth: "562px",
  bgcolor: "background.paper",
  borderRadius: "4px",
  boxShadow: 24,
  '@media screen and (max-width: 768px)': {
    width: '98%',
  },
   '@media screen and (min-width:360px) and (max-width: 399px)': { 
    height:"75vh"
    },
    '@media screen and (min-width: 375px) and (max-width:450px)': { 
     height:"80vh"
    },
    '@media screen and (min-width: 410px) and (max-width:818px)': { 
      height:"62vh"
     },
    '@media screen and (min-width: 820px) and (max-width:849px)': { 
     height:"70vh"
    },
    '@media screen and (min-width: 850px) and (max-width:1026px)': { 
     height:"95vh"
    },
    '@media screen and (min-width: 850px) and (max-width:855px)': { 
     height:"68vh"
    },
};


function SingleTeacherSignupModal(props: any) {
  const location = useLocation()
  const deviceOrientation = useAppSelector(
    (state: any) => state.user.deviceOrientation
  )
  const isPortrait = deviceOrientation?.data?.isPortrait
  console.log("deviceOrientationdeviceOrientation", deviceOrientation.data)
  const {
    openSignupModal,
    setOpenSignupModal,
    showErr,
    errorMessage,
    setShowValidationModal,
  } = props;
  const gradesArr = [
    "K",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    // "9",
    // "10",
    // "11",
    // "12",
  ];


  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [schoolEmail, setSchoolEmail] = useState<any>();
  const [schoolName, setSchoolName] = useState<any>("");
  const [selectedGrades, setSelectedGrades] = useState<any>([]);
  const [curriculum, setCurriculum] = useState<any>("");
  const [password, setPassword] = useState<any>("");
  const [confirmPassword, setConfirmPassword] = useState<any>("");
  const [jobTitle, setJobTitle] = useState<any>("");
  const [showPassword, setShowPassword] = useState<Boolean>(false);
  const [showConfirmPassword, setConfirmShowPassword] =
    useState<Boolean>(false);
  const userData = useState(getItem("userDetails"))
  const history = useHistory();


  const searchParams = qs.parse(window.location.search);
  const emailQueryStrValue = searchParams["email"]

  const isSignUpFromQueryStrValue =
    searchParams["?signup"] === "yes" ? true : false;
  const [isSignUpFromQueryStr, setisSignUpFromQueryStr] = useState(
    isSignUpFromQueryStrValue
  );

  const isOtpFromQueryStrValue =
    searchParams["?otp"] === "yes" ? true : false;
  const [isOtpFromQueryStr, setisOtpFromQueryStr] = useState(
    isOtpFromQueryStrValue
  );

  useEffect(() => {
    setisOtpFromQueryStr(isOtpFromQueryStrValue)
  }, [isOtpFromQueryStrValue])

  useEffect(() => {
    if (isSignUpFromQueryStr && searchParams["id"]) {
      dispatch(getUserEmailFromEmailUuid(searchParams["id"]))
    }
  }, [])


  const getSignUpUserStatus = useAppSelector(
    (state: any) => state.user?.signUpData
  );
  const emailValidationObj = useAppSelector(
    (state: any) => state.user?.emailValidationObj
  );
  const otpData = useAppSelector(
    (state: any) => state.user?.validateOtpData
  );

 

  const [emailError, setEmailError] = useState<any>(false);
  // const [showValidationModal,setShowValidationModal]=useState<any>(false)
  const [passwordError, setPasswordError] = useState<any>(false);
  const [showConfirmPasswordErrr, setShowConfirmPasswodErr] =
    useState<any>(false);

  const [signUp, setSignUp] = useState<any>(false);

  const [errMsg, setErrMsg] = useState<string>("");
  const [showErrMsg, setShowErrMsg] = useState<boolean>(false);

  const [firstNameError, setFirstNameError] = useState<string>("");
  const [lastNameError, setLastNameError] = useState<string>("");
  const [emailError2, setEmailError2] = useState<string>("");
  const [schoolNameError, setSchoolNameError] = useState<string>("");
  const [passwordError2, setPasswordError2] = useState<string>("");
  const [confirmPasswordError2, setConfirmPasswordError2] =
    useState<string>("");
  const [jobTitleError, setJobTitleError] = useState<string>("");

  const [confirmPasswordError, setConfirmPasswordError] = useState<string>("");
  const [curriculumError, setCurriculumError] = useState<string>("");
  const [schoolEmailError, setSchoolEmailError] = useState("");
  const [gradesError, setGradesError] = useState<string | null>(null);
  const [hearSelectedOptions, setHearSelectedOptions] = useState<any>([]);
  const [openPhoneNumberModal, setOpenPhoneNumberModal] = useState(false);
  const [phNumber, setPhNumber] = useState<any>("");
  const [phOtp, setPhOtp] = useState(false)
  const [otp, setOtp] = useState('');
  const [phNumberError, setPhNumberError] = useState("");
  const [checkEmail, setCheckEmail] = useState(false);
  // for useractivities
  const [userId, setUserId] = useState(null);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const openPhoneNumberModalHandler = () => {
    setOpenSignupModal(false); // Close existing modal
    setOpenPhoneNumberModal(true); // Open modal with phone number field
  };
  const handleOtpChange = (otp: any) => {
    setShowOtpError("")
    setOtp(otp);
  };

  const openPhoneNumberOtpHandler = () => {
    setOtp("")
    setShowOtpError("")
    dispatch(
      apiUserPhoneValidate({
        email: emailQueryStrValue ? emailQueryStrValue : schoolEmail?.toLowerCase(),
        phoneNumber: phNumber
      })
    );

    // setOpenSignupModal(false); // Close existing modal
    setOpenPhoneNumberModal(false); // Open modal with phone number field
    setPhOtp(true)
  };
  const handlePhoneChange = (e: any) => {
    setPhNumber(e)
  }
  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setHearSelectedOptions(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleChangeJobTitles = (event: any) => {
    const {
      target: { value },
    } = event;
    setJobTitle(value)
    setJobTitleError("")
  };

  const optionsForHearAbtUs: any = [
    "Internet Search",
    "Email",
    "Professional Conference",
    "Social Media",
    "Recommended by a friend",
  ];

  const jobTitleOptions = [
    "Teacher", 
    "Admin",
    "Other"
  ]

  const [allFieldsError, setAllFieldsError] = useState<any>("");
  const dispatch = useAppDispatch();

  const closeModal = () => {
    setOpenSignupModal(false);
    setisSignUpFromQueryStr(false);
    setisOtpFromQueryStr(false);
    setName("");
    setLastName("");
    setSchoolEmail("");
    setSchoolName("");
    setSelectedGrades("");
    setCurriculum("");
    setPassword("");
    setConfirmPassword("");
    setFirstNameError("");
    setGradesError("");
    setSchoolEmailError("");
    setCurriculumError("");
    setPasswordError2("");
    setConfirmPasswordError2("");
    setSchoolNameError("");
    setFirstNameError("");
    setLastNameError("");
    setOpenPhoneNumberModal(false);
    setPhOtp(false)
    history.push("")
    setCheckEmail(false)
  };
  const closeModals = () => {
    setOpenSignupModal(true);
  }

  const closeModelOtp = () => {
    setOpenPhoneNumberModal(true)
    setPhOtp(false)
  }



  const handleGradeSelection = (selectedGrade: any) => {
    let a = [];
    if (selectedGrades.includes(selectedGrade)) {
      a = selectedGrades.filter((grade: any) => grade !== selectedGrade);
    } else {
      a = [...selectedGrades, selectedGrade];
    }

    if (a.length === 0) {
      setGradesError("Select grades");
    } else {
      setGradesError(null);
    }
    setSelectedGrades(a);
  };

  const validateEmail = (email: string): boolean => {
    // Regular expression for a more comprehensive email validation
    // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    // if (!email.includes('@')) {
    //   return false;
    // }
    return emailRegex.test(email);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value;
    setSchoolEmail(email);

    if (validateEmail(email)) {
      setEmailError(false);
      setSchoolEmailError(schoolEmailError);
    } else {
      setEmailError(true);
      setSchoolEmailError("");
    }
  };

 

  useEffect(() => {
    // const baseUrl = 'https://dev.aautischool.com';
    const baseUrl = `${window.location.href}?emailverify=${getSignUpUserStatus?.data?.result?.emailValidationCode}`;
    if (
      getSignUpUserStatus?.status === "failed" &&
      getSignUpUserStatus?.error !== "" &&
      getSignUpUserStatus?.error !== null
    ) {
      // const error = getSignUpUserStatus?.error;
      setShowErrMsg(true);
      // toast(`${error}`, {
      //   style: {
      //     fontSize: '14px',
      //     width: '320px',
      //   },
      // });
    } else if (getSignUpUserStatus?.status == "succeeded") {
      if (getSignUpUserStatus.data.result.waitingForApproval) {
        setOpenSignupModal(false);
        setCheckEmail(true);
      } else {
        setShowErrMsg(false);
        setCurriculum("NGSS");
        setOpenSignupModal(false);
        setOpenPhoneNumberModal(true);
      }

    }

  }, [getSignUpUserStatus]);
  const hostname = window && window.location && window.location.hostname;
  const appContext = useContext(AppContext);
  const [showOtpError, setShowOtpError] = useState("")
  const userDetailsVerified=useAppSelector(
    (state: any) => state?.user
  );


  useEffect(() => {
    if (otpData?.status === "succeeded" && userDetailsVerified?.userVerfiedStatus?.status === "succeeded" ) {
      
     
     

      const userProfile = otpData?.data?.result
      const districtId = otpData?.data?.districtId
      const schoolId = otpData?.data?.schoolId
      let tempData = {}
      if (districtId) {
        tempData = { districtId }
      }
      if (schoolId) {
        tempData = { ...tempData, schoolId }
      }
      console.log('userProfile', otpData, districtId, schoolId)

      if ((userProfile?.role?.toLowerCase() === "teacher" || userProfile?.result?.role?.toLowerCase() === "teacher") || (userProfile?.role?.toLowerCase() === "Teacher" || userProfile?.result?.role?.toLowerCase() === "Teacher")) {

        const userDetails = { ...userProfile, sections: [], selectedSection: userProfile?.sections ? userProfile?.sections[0] : {}, ...tempData, employeeId: userProfile?.sourcedId }
        appContext?.setTeacherUserDetails(userDetails, "teacherUserDetails");
        if (hostname.includes('dev')) {
          window.open("https://dev.aautischool.com/teacher", "_self")
        } else if (hostname.includes('qa')) {
          window.open("https://qa.aautischool.com/teacher", "_self")
        } else if (hostname.includes('prod')) {
          window.open("https://prod.aautischool.com/teacher", "_self")
        } else if(hostname.includes('uat')) {
          window.open("https://uat.aautischool.com/teacher","_self")
        } else {
          window.open("https://dev.aautischool.com/teacher", "_self")
        }
      }
    } else if (otpData?.status === "failed") {
      setShowOtpError(otpData?.error)
    }


  }, [userDetailsVerified?.saveContactInHubspot?.status ,userDetailsVerified?.userVerfiedStatus?.status])

  

  

  const handlePasswordChange = (e: any) => {
    const newPassword = e.target.value;
    setPassword(newPassword);

    const minLengthRegex = /^.{8,}$/; // At least 8 characters
    const maxLengthRegex = /^.{1,20}$/; // Maximum 20 characters
    const firstLetterCapitalRegex = /^[A-Z]/; // First letter as a capital letter
    const specialCharacterRegex = /[!@#$%^&*(),.?":{}|<>]/; // At least one special character

    const isMinLengthValid = minLengthRegex.test(newPassword);
    const isMaxLengthValid = maxLengthRegex.test(newPassword);
    const isFirstLetterCapitalValid = firstLetterCapitalRegex.test(newPassword);
    const isSpecialCharacterValid = specialCharacterRegex.test(newPassword);
    const emailRegexValid = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    if (isMinLengthValid && isMaxLengthValid) {
      setPasswordError(false);
      setPasswordError2(passwordError2)
    } else {
      setPasswordError(true);
      setPasswordError2("")
    }
  };

  const handleBlur = (fieldName: any) => {
    switch (fieldName) {
      case "name":
        if (name === "") {
          setFirstNameError("Please enter your name");
        } else if (name.length < 1) {
          setFirstNameError("First name must be at least 1 characters long");
        } else {
          setFirstNameError("");
        }
        break;
      case "lastName":
        if (lastName === "") {
          setLastNameError("Please enter your last name");
        } else if (lastName.length < 1) {
          setLastNameError("Last name must be at least 1 characters long");
        } else {
          setLastNameError("");
        }
        break;
      case "password":
        if (password === "") {
          setPasswordError2("Please enter your password");
        } else {
          setPasswordError2("");
        }
        break;
      case "confirmPassword":
        if (confirmPassword === "") {
          setConfirmPasswordError2("Please confirm your password");
        } else {
          setConfirmPasswordError2("");
        }
        break;
      case "schoolEmail":
        if (schoolEmail === "") {
          setSchoolEmailError("Please enter your school email");
        } else {
          setSchoolEmailError("");
        }
        break;

      case "schoolName":
        if (schoolName === "") {
          setSchoolNameError("Please enter your school name");
        } else if (schoolName.length < 3) {
          setSchoolNameError("School name must be at least 3 characters long");
        } else {
          setSchoolNameError("");
        }
        break;
      case "curriculum":
        if (curriculum === "") {
          setCurriculumError("Please enter curriculum name");
        } else {
          setCurriculumError("");
        }
        break;
      case "jobTitle":
        if (jobTitle === "") {
          setJobTitleError("Please select your job title");
        } else {
          setJobTitleError("");
        }
        break;
      default:
        break;
    }
  };

  const handleConfirmPassErr = (e: any) => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);

    if (newConfirmPassword !== password) {
      setShowConfirmPasswodErr(true);
      setConfirmPasswordError2("")
    } else {
      setShowConfirmPasswodErr(false);
      setConfirmPasswordError2(confirmPasswordError2)
    }
  };

  useEffect(() => {
    setFirstNameError("");
    setLastName("");
    setSchoolEmail("");
    setSchoolName("");
    setCurriculum("");
    setSelectedGrades([]);
  }, []);
  
  function getCurrentCSTTimeString(date:any): string {
    const cstOptions: any = {
        timeZone: 'America/Chicago',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
    };

    const d = new Intl.DateTimeFormat('en-US', cstOptions).format(date);
    return  new Date(d).toISOString(); 
    
}


  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (name === "") {
      setFirstNameError("Please enter your name");
    }
    if (lastName === "") {
      setLastNameError("Please enter your last name");
    }
    if (schoolName === "") {
      setSchoolNameError("Please enter your school name");
    }
    if (password === "") {
      setPasswordError2("Please enter your password");
    }
    if (confirmPassword === "") {
      setConfirmPasswordError2("Please confirm your password");
    }
    if (schoolEmail === "") {
      setSchoolEmailError("Please enter your school email");
      setEmailError(false);
    }
    if (curriculum === "") {
      setCurriculumError("please enter current curriculums");
    }
    if (jobTitle === "") {
      setJobTitleError("please select your job title");
    }
    if (firstNameError || lastNameError || passwordError || confirmPasswordError || gradesError || confirmPasswordError2 || curriculumError || showConfirmPasswordErrr || passwordError2 || passwordError || schoolEmailError || emailError || schoolNameError || curriculumError || jobTitleError) {
      return null
    }

    if (selectedGrades.length === 0) {
      setGradesError("Select grades");
    } else {
      const data: any = {
        firstName: name,
        lastName: lastName,
        jobTitle,
        grades: selectedGrades,
        email: schoolEmail?.toLowerCase(),
        schoolName: schoolName,
        currentCurriculums: curriculum,
        password: password,
        hearAboutUs: hearSelectedOptions,
      }
      // dispatch(
      //   apiUserSignUp(data)
      // );

     

      dispatch(
        apiUserSignUp(data)
      ).then(response => {
        console.log('Response from apiUserSignUp:', response);
        if (response?.payload?.status === 201) {
          setUserId(response?.payload?.result?.userId)
           dispatch(apiSaveUserActivity({
            userId:response?.payload?.result?.userId,
            verificationSentTime:getCurrentCSTTimeString(new Date()),
            verification2SentTime:null,
            isVerified:false,
            email:response?.payload?.result?.email,
            sectionCreatedTime:[],
            studentCreatedTime:[],
            source:"TEACHER_SIGNUP",
            section1stEmailSentTime:null,
            section2ndEmailSentTime:null,
            student1stEmailSentTime:null,
            student2ndEmailSentTime:null,
            firstName:name,
            school:schoolName
        }));
        dispatch(apiCreateContactInHubspot({
          email: schoolEmail?.toLowerCase() ,
          accountstatus:"OTP_NOT_VERIFIED",
          firstName:name,
          school:schoolName,
          jobTitle,
          source: "TEACHER_SIGNUP"
        }));
       
        } else {
          console.error('User sign-up failed:', response);
        }
      }).catch(error => {
        console.error('Error occurred:', error);
      });
    }

  };
  useEffect(() => {
    if (getSignUpUserStatus.status === "succeeded" && getSignUpUserStatus?.data?.result?.email) {
      const data: any = {
        firstName: name,
        lastName: lastName,
        grades: selectedGrades,
        email: schoolEmail?.toLowerCase(),
        schoolName: schoolName,
        currentCurriculums: curriculum,
        password: password,
        hearAboutUs: hearSelectedOptions,
      }
      saveItem("userDetails", data)
    }
  })

  const userDetails=useAppSelector(
    (state: any) => state?.user?.userActivityDetails
  );
  const emailQueryStrValueFollowUp = new URLSearchParams(location.search).get('email');

 

  const handleSubmits = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setShowOtpError('');
    dispatch(apiUserPhoneOtpValidate({
      email: emailQueryStrValue ? emailQueryStrValue.toLowerCase() : schoolEmail?.toLowerCase(),
      phoneNumber: phNumber,
      otp: otp
    }));
  };

 

  
  const [emailProcessed, setEmailProcessed] = useState(false);

  useEffect(() => {
    if (!emailProcessed) {
      const emailToFetch = emailQueryStrValue ? emailQueryStrValue : schoolEmail?.toLowerCase();
      dispatch(apiGetUserActivityDetails({ email: emailToFetch }));
      setEmailProcessed(true); // Mark email as processed
    }
  }, [location.search, emailQueryStrValue, schoolEmail, emailProcessed, dispatch]);


  useEffect(() => {
    if (otpData?.status === 'succeeded') {
      const userDatas = userDetails?.data?.[0] ?? {};
      console.log(userDetails, "Fetched User Details");

      dispatch(apiUserVerified({
        userId: userId || userDatas.userId
      }));

      dispatch(apiUpdateContactStatus({
        email:schoolEmail?.toLowerCase() || userDatas.email?.toLowerCase()

      }))

      dispatch(apiSaveContactNotes({
        email: schoolEmail?.toLowerCase() || userDatas.email?.toLowerCase(),
        message:"ACCOUNT VERIFIED"
        
      }));
    }
  }, [otpData?.status, userDetails, userId, schoolEmail, name, schoolName, phNumber, dispatch]);

  

  

  
 

 

 

  const disabled1 =
    name === "" ||
    lastName === "" ||
    password === "" ||
    confirmPassword === "" ||
    passwordError !== false ||
    emailError !== false ||
    showConfirmPasswordErrr !== false ||
    firstNameError !== "" ||
    lastNameError !== "" ||
    emailError2 !== "" ||
    schoolNameError !== "" ||
    passwordError2 !== "" ||
    confirmPasswordError2 !== "" ||
    confirmPasswordError !== "" ||
    curriculumError !== "" ||
    schoolEmailError !== "" ||
    gradesError !== null ||
    password !== confirmPassword ||
    getSignUpUserStatus?.status === "loading" ||
    jobTitle === "" ||
    jobTitleError !== ""



  const disabled2 = otp === ""
  const isOtpSending = otpData?.status === "loading"
  const styles: any = isPortrait === false ? {
    position: "absolute",
    top: "3%",
    left: "50%",
    transform: "translate( -50%,0)",
    width: "100%",
    maxWidth: "562px",
    bgcolor: "background.paper",
    borderRadius: "4px",
    boxShadow: 24,
    '@media screen and (max-width: 768px)': {
      width: '98%',
    },
    '@media screen and (min-width: 460px) and (max-width:680px)': {
      height: "90vh",
    },
    '@media screen and (min-width: 681px) and (max-width:849px)': {
      height: "60vh",
    },
    '@media screen and (min-width: 850px) and (max-width:1023px)': {
      height: "92vh",
    },
    '@media screen and (min-width: 1024px) and (max-width:1400px)': {
      height: "70vh",
      marginTop: "20px",
    },

  } : {
    ...modalStyle,
  }

  const Phonestyles: any = isPortrait === false ? {
    position: "absolute",
    top: "3%",
    left: "50%",
    transform: "translate( -50%,0)",
    width: "100%",
    maxWidth: "562px",
    bgcolor: "background.paper",
    borderRadius: "4px",
    boxShadow: 24,
    '@media screen and (max-width: 768px)': {
      width: '98%',
    },
    '@media screen and (min-width: 460px) and (max-width:680px)': {
      height: "90vh",
    },
    '@media screen and (min-width: 850px) and (max-width:1023px)': {
      height: "90vh",
    },
    '@media screen and (min-width: 1024px) and (max-width:1190px)': {
      height: "80vh",
      marginTop: "20px",
    },
    '@media screen and (min-width: 1091px) and (max-width:1400px)': {
      height: "65vh",
      marginTop: "20px",
    },
    '@media screen and (min-width: 1401px) and (max-width:1800px)': {
      height: "80vh",
      marginTop: "40px",
    },


  } : {
    ...modalStyle1,
    height: "80vh",
    '@media screen and (min-width: 850px) and (max-width:1026px)': { 
      height:"80vh"
     },
     '@media screen and (min-width: 768px) and (max-width:1024px)': { 
      height: "55vh",
     },
     '@media screen and (min-width: 540px) and (max-width:720px)': { 
      height: "80vh",
     },
     '@media screen and (min-width: 1024px) and (max-width:1400px)': { 
      height: "40vh",
      marginTop:"20px"
     },
     overflow:"hidden",top:"9%" 
   
  }

  const Otpstyles: any = isPortrait === false ? {
    position: "absolute",
    top: "3%",
    left: "50%",
    transform: "translate( -50%,0)",
    width: "100%",
    maxWidth: "562px",
    bgcolor: "background.paper",
    borderRadius: "4px",
    boxShadow: 24,
    '@media screen and (max-width: 768px)': {
      width: '98%',
    },
    '@media screen and (min-width: 460px) and (max-width:680px)': {
      height: "90vh",
    },
    '@media screen and (min-width: 850px) and (max-width:1023px)': {
      height: "92vh",
    },
    '@media screen and (min-width: 1024px) and (max-width:1190px)': {
      height: "80vh",
      marginTop: "20px",
    },
    '@media screen and (min-width: 1091px) and (max-width:1400px)': {
      height: "67vh",
      marginTop: "20px",
    },
    '@media screen and (min-width: 1401px) and (max-width:1800px)': {
      height: "80vh",
      marginTop: "40px",
    },
  } : {
    ...modalStyle4,

    height: "80vh",
          overflow:"hidden",top:"9%" ,
         

  }

  const imagestyles: any = isPortrait === false ? {
    '@media screen and (min-width: 460px) and (max-width:680px)': {
      height: "10px", width: "10px",
    },
  } : { height: "17px", width: "17px", }

  const otpcheck:any=isPortrait===false ?{overflowY:"auto",
  '@media screen and (min-width: 681px) and (max-width:900px)': { 
    height:"55vh",
   },
   '@media screen and (min-width: 460px) and (max-width:680px)': { 
    height:"55vh",
   },
   '@media screen and (min-width: 901px) and (max-width:990px)': { 
    height:"60vh",
   },
  
  }:{}
  const gmailchek:any=isPortrait===false ?{overflowY:"auto",
  '@media screen and (min-width: 460px) and (max-width:680px)': { 
    height:"55vh",
   },
  '@media screen and (min-width: 681px) and (max-width:900px)': { 
    height:"55vh",
   },
   '@media screen and (min-width: 901px) and (max-width:1400px)': { 
    height:"60vh",
   },
  
  }:{}
  const checkEmailStyles:any=isPortrait===false ?{overflowY:"auto",
  position: "absolute",
  top: "10%",
  left: "50%",
  transform: "translate( -50%,0)",
  width: "100%",
  maxWidth: "562px",
  bgcolor: "background.paper",
  borderRadius: "4px",
  boxShadow: 24,
  '@media screen and (max-width: 768px)': {
    width: '98%',
  },
  '@media screen and (min-width: 460px) and (max-width:680px)': {
    height: "60vh",
  },
  '@media screen and (min-width: 681px) and (max-width:849px)': {
    height: "50vh",
  },
  '@media screen and (min-width: 850px) and (max-width:1023px)': {
    height: "70vh",
  },
  '@media screen and (min-width: 1024px) and (max-width:1400px)': {
    height: "60vh",
  },
  
  }:{
    ...modalStyle3, 
      top: "10%",
      height: "30vh",
    
  }

  const errorMessageSignup=()=>{
    if(getSignUpUserStatus?.error==="This email is already registered, Please Sign In."){
          return <div style={{display:'flex',flexDirection:"row",alignItems:'center',justifyContent:"center"}}>
            <p style={{color:'#333',fontSize:"12px",paddingTop:"15px",paddingRight:"5px",fontFamily:"HelveticaNeue-Medium"}} >This email is already registered, Please</p>
            <span onClick={closeModal} style={{color:"blue",fontSize:"12px",textDecoration: "underline",padding:0,margin:0,fontFamily:"HelveticaNeue-Medium"}}>Sign In</span>
          </div>
    } else {
      return <p className="responsive-errorMessage">{getSignUpUserStatus?.error}</p>
    }
  };

  return (
    <div>
      <div>
        <Modal open={openSignupModal || isSignUpFromQueryStr} style={{ height: "100vh" }}>
          <Box sx={[styles,]}
            className="responsive-model"

          >
            <div style={{ display: "flex", justifyContent: "space-between", borderBottom: "1px solid #e9e9e9" }}>

              <div style={{ paddingLeft: "20px", marginTop: "10px", }}>
                <SVGAautiLogo style={{ height: "40px", width: "60px" }} />
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", cursor: "pointer", marginRight: "30px" }} onClick={closeModal}>
                <SVGCloseIcon style={{ height: "17px", width: "17px" }} fill="#717a82" />
              </div>
            </div>
            {showErrMsg && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#ffe4d6",
                  textAlign: "center",
                  height: "auto",
                  padding:"5px",
                  paddingLeft: 10,
                  paddingRight: 10,
                }}
              >
                <IconButton sx={{ mb: 0 }}>
                  <ReportProblemIcon fontSize="small" color="warning" />
                </IconButton>

                <p
                  // paragraph 
                  // mt={2}
                  // sx={{
                  //   fontSize: 12,
                  //   color: "#e04e4e",
                  //   paddingTop: 2,
                  //   paddingBottom: 1.5,
                  //   paddingRight: 1,
                  // }}
                  className="responsive-errorMessage"
                >
                  {getSignUpUserStatus?.error?.includes("undefined")
                    ? "please verify the email once"
                    : errorMessageSignup()}
                </p>
              </Box>
            )}
            <Box sx={isPortrait === false ? {
              overflowY: "auto", marginBottom:showErrMsg?"6px" :"8px",
              '@media screen and (min-width: 460px) and (max-width:680px)': {
                height: "60vh",
              },
              '@media screen and (min-width: 681px) and (max-width:900px)': {
                height: "52vh",
              },
              '@media screen and (min-width: 1091px) and (max-width:1400px)': {
                height: "55vh",
                marginTop: "20px",
              },
            } : {
              '@media screen and (min-width: 1024px) and (max-width:1400px)': {
                height: "40vh",
                marginTop: "20px",
              },
              
            }}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "#3166ba",
                    fontFamily: "HelveticaNeue-Medium",
                    fontWeight: "500",
                    marginTop: "10px",
                    textAlign: "center",
                  }}
                >
                  Create Your Free Account
                </Typography>
              </Box>

              <Box
                sx={{
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  height: "auto",
                  marginBottom: "40px",
                  // height:"95vh",

                }}
                className="responsive-modelHeight"
              >
                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "5px", }}>
                  <div style={{ display: 'flex', flexDirection: "column", width: "70%", marginRight: "10px" }}>
                    <p className="responsive-p">First Name</p>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      fullWidth
                      required={true}
                      value={name}
                      inputProps={{ maxLength: 40, fontsize: "10px" }}
                      InputLabelProps={{
                        style: { ...textFieldStyles, textAlign: "center" },
                        // shrink: true 
                      }}
                      label="Enter Your First Name"
                      onChange={(e) => {
                        const firstName = e.target.value.replace(/[^A-Za-z\s]/g, "");
                        if (firstName.length < 1) {
                          setFirstNameError("First name must be at least 1 characters long");
                          setName(firstName)
                        }
                        else if (firstName.length > 20) {
                          setFirstNameError("First name should be 20 characters long");
                          setName(firstName)
                        } else {
                          setName(firstName);
                          setFirstNameError(""); // Reset firstNameError if input is valid
                        }
                      }}
                      onBlur={() => handleBlur("name")}
                    />
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      {firstNameError && (
                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                          <ReportGmailerrorredRoundedIcon sx={{ color: "red", fontSize: "10px" }} />
                          <Typography
                            sx={{ color: "red", fontSize: "8px", }}
                          >
                            {firstNameError}
                          </Typography>
                        </div>
                      )}
                    </div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: "column", width: "72%" }}>
                    <p className="responsive-p">Last Name</p>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={lastName}
                      required={true}
                      inputProps={{ maxLength: 40 }}
                      InputLabelProps={{
                        style: { ...textFieldStyles },
                        // shrink: true 
                      }}
                      label="Enter Your Last Name"
                      onChange={(e) => {
                        const lastName = e.target.value.replace(/[^A-Za-z\s]/g, "");
                        if (lastName.length < 1) {
                          setLastNameError("Last name must be at least 1 characters long");
                          setLastName(lastName)
                        } else if (lastName.length > 20) {
                          setLastNameError("Last name should be 20 characters long");
                          setLastName(lastName)
                        }
                        else {
                          setLastName(lastName);
                          setLastNameError(""); // Reset firstNameError if input is valid
                        }
                      }}
                      onBlur={() => handleBlur("lastName")}
                    />
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      {lastNameError && (
                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                          <ReportGmailerrorredRoundedIcon sx={{ color: "red", fontSize: "10px" }} />
                          <Typography
                            sx={{ color: "red", fontSize: "8px", }}
                          >
                            {lastNameError}
                          </Typography>
                        </div>
                      )}

                    </div>
                  </div>
                </div>

                <p id="job-title" className="responsive-p mt-2">Job Title</p>

                {/* <div style={{ marginBottom: "10px", }}>
                  <FormControl sx={{ width: "100%" }}>
                    <Select
                      MenuProps={MenuProps}
                      multiple
                      displayEmpty
                      input={<OutlinedInput />}
                      inputProps={{ "aria-label": "Without label" }}
                      value={jobTitles}
                      onChange={handleChangeJobTitles}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return (
                            <p
                              style={{
                                marginBottom: "0px",
                                padding: "2px",
                                fontSize: "10px",
                                fontStyle: "none",
                                fontFamily: "HelveticaNeue-Medium",
                                color: "rgba(167, 167, 167, 0.8)",
                              }}
                            >
                              Select Your Job Title
                            </p>
                          );
                        }

                        return selected.join(", ");
                      }}
                      sx={{ height: "40px", }}
                      id="my-select"
                    >
                      {jobTitleOptions.map((name: any) => (
                        <MenuItem key={name} value={name} sx={{ padding: "0px" }}>
                          <Checkbox
                            checked={jobTitles.indexOf(name) > -1}
                          />
                          <ListItemText
                            primary={name}
                            sx={{ fontFamily: "HelveticaNeue-Medium", fontSize: "12px" }}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div> */}
                
                <FormControl sx={{ width: "100%", mb: "5px" }}>
                    <Select
                      MenuProps={MenuProps}
                      displayEmpty
                      input={<OutlinedInput />}
                      inputProps={{ "aria-label": "Without label" }}
                      value={jobTitle}
                      onChange={(e:any) => {
                        setJobTitle(e.target.value?.trim())
                        setJobTitleError("")
                      }}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return (
                            <p
                              style={{
                                marginBottom: "0px",
                                padding: "2px",
                                fontSize: "14px",
                                fontStyle: "none",
                                fontFamily: "HelveticaNeue-Medium",
                                color: "rgba(167, 167, 167, 0.8)",
                              }}
                            >
                              Select Your Job Title
                            </p>
                          );
                        }

                        return selected;
                      }}
                      sx={{ height: "40px", }}
                      id="my-select"
                    >
                      {jobTitleOptions.map((name: any) => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>



                {/* <FormControl sx={{width: "100%"}}>
                  <InputLabel id="job-title-label" sx={{  border: "0px solid blue" }}>Enter Your Job Title</InputLabel>
                  <Select
                    labelId="job-title-label"
                    id="job-title"
                    value={jobTitle}
                    // label="Enter Your Job Title"
                    input={<OutlinedInput label="Enter Your Job Title" />}

                    onChange={(e:any) => {
                      console.log("vaaaaaaa", e.target)
                      setJobTitle(e.target.value?.trim())
                      setJobTitleError("")
                    }}
                  size="small"

                    // sx={{ ...textFieldStyles,

  
                    //   // marginRight: "10px",
                    //   // mb: 2,
                    //   width: "100%",
                    //   height: "40px",
                    //   marginBottom:"8px",
                    //   color:"#333333",
                    //   fontSize:'14px',
                    // }}
                  >
                    <MenuItem value={"Teacher"}>Teacher</MenuItem>
                    <MenuItem value={"Student"}>Student</MenuItem>
                    <MenuItem value={"Other"}>Other</MenuItem>
                  </Select>
                </FormControl> */}
                {/* <TextField
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  fullWidth
                  multiline={true}
                  rows={1}
                  sx={{ height: "45px", }}
                  inputProps={{ maxLength: 40 }}
                  InputLabelProps={{
                    style: { ...textFieldStyles },
                    // shrink: true 
                  }}
                  label="Job Title"
                  value={jobTitle}
                  onChange={(e) => {
                    setJobTitle(e.target.value?.trim())
                    setJobTitleError("")
                  }}
                  onBlur={() => handleBlur("jobTitle")}
                /> */}
                <div style={{ display: "flex", flexDirection: "row" }}>
                  {jobTitleError && (
                    <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                      <ReportGmailerrorredRoundedIcon sx={{ color: "red", fontSize: "10px" }} />
                      <Typography
                        sx={{ color: "red", fontSize: "8px", }}
                      >
                        {jobTitleError}
                      </Typography>
                    </div>
                  )}
                </div>

                <div style={{ marginTop: "5px", marginBottom: "5px" }}>
                  <p className="responsive-p">Grades Taught</p>
                  {gradesArr.map((each: any, index: any) => {
                    return (
                      <div
                        key={each}
                        style={{
                          display: "inline-block",
                          padding: " 10px 22.5px 10px 22.5px",
                          border: "1px solid #e9e9e9",
                          cursor: "pointer",
                          backgroundColor: selectedGrades.includes(each)
                            ? "#3166ba"
                            : "white",
                        }}
                        onClick={() => handleGradeSelection(each)}
                      >
                        <Typography
                          sx={{ color: selectedGrades.includes(each) ? "#fff" : "#000", }}
                        >
                          {each}
                        </Typography>
                      </div>
                    );
                  })}
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    {gradesError && (
                      <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                        <ReportGmailerrorredRoundedIcon sx={{ color: "red", fontSize: "10px" }} />
                        <Typography
                          sx={{ color: "red", fontSize: "8px", }}
                        >
                          {gradesError}
                        </Typography>
                      </div>
                    )}
                  </div>

                </div>

                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "5px", }}>
                  <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "column", width: "90%", marginRight: "10px" }}>
                    <p className="responsive-p">Email</p>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={schoolEmail}
                      inputProps={{ maxLength: 40 }}
                      label="Enter Your School Email"
                      onChange={handleEmailChange}
                      onBlur={() => handleBlur("schoolEmail")}
                      InputLabelProps={{
                        style: { ...textFieldStyles },
                        // shrink: true 
                      }}
                      // sx={{marginTop:"-5px"}}
                      required
                    />
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      {schoolEmailError && (
                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                          <ReportGmailerrorredRoundedIcon sx={{ color: "red", fontSize: "10px" }} />
                          <Typography
                            sx={{ color: "red", fontSize: "8px", }}
                          >
                            {schoolEmailError}
                          </Typography>
                        </div>
                      )}
                      {emailError && !schoolEmailError && (
                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                          <ReportGmailerrorredRoundedIcon sx={{ color: "red", fontSize: "10px" }} />
                          <Typography
                            sx={{ color: "red", fontSize: "8px", }}
                          >
                            Please enter a valid email address
                          </Typography>
                        </div>
                      )}
                    </div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: "column", width: '90%' }}>
                    <p className="responsive-p">School Name</p>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={schoolName}
                      inputProps={{ maxLength: 40 }}
                      InputLabelProps={{
                        style: { ...textFieldStyles },
                        // shrink: true 
                      }}
                      label="Enter Your School Name"
                      onChange={(e) => {
                        const schoolName = e.target.value.replace(/[^A-Za-z\s]/g, "");
                        if (schoolName.length < 3) {
                          setSchoolNameError("Scholl name must be at least 3 characters long");
                          setSchoolName(schoolName)
                        } else {
                          setSchoolName(schoolName)
                          setSchoolNameError("")
                        }
                      }}
                      onBlur={() => handleBlur("schoolName")}
                    />
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      {schoolNameError && (
                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                          <ReportGmailerrorredRoundedIcon sx={{ color: "red", fontSize: "10px" }} />
                          <Typography
                            sx={{ color: "red", fontSize: "8px", }}
                          >
                            {schoolNameError}
                          </Typography>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "5px" }}>


                  <div style={{ width: "90%", marginRight: "10px", marginBottom: "5px" }}>
                    <p className="responsive-p">Password</p>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={password}
                      inputProps={{ maxLength: 40 }}
                      label="Enter Your Password"
                      onChange={handlePasswordChange}
                      onBlur={() => handleBlur("password")}
                      InputLabelProps={{
                        style: { ...textFieldStyles },
                        // shrink: true 
                      }}
                      // sx={{marginTop:"-5px"}}
                      required
                      type={showPassword ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {showPassword ? (
                              <Visibility
                                fontSize="small"
                                onClick={() => setShowPassword(false)}
                              />
                            ) : (
                              <VisibilityOff
                                fontSize="small"
                                onClick={() => setShowPassword(true)}
                              />
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />

                    <div style={{ display: "flex", flexDirection: "row" }}>
                      {passwordError2 && (
                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                          <ReportGmailerrorredRoundedIcon sx={{ color: "red", fontSize: "10px" }} />
                          <Typography
                            sx={{ color: "red", fontSize: "8px", }}
                          >
                            {passwordError2}
                          </Typography>
                        </div>
                      )}

                      {passwordError && !passwordError2 && (
                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                          <ReportGmailerrorredRoundedIcon sx={{ color: "red", fontSize: "10px" }} />
                          <Typography
                            sx={{ color: "red", fontSize: "8px", }}
                          >
                            password length between 8 to 20
                          </Typography>
                        </div>
                      )}
                    </div></div>
                  <div style={{ display: 'flex', flexDirection: "column", width: "90%" }}>
                    <p className="responsive-p">Confirm Password</p>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={confirmPassword}
                      inputProps={{ maxLength: 40 }}
                      type={showConfirmPassword ? "text" : "password"}
                      required={true}
                      // sx={{marginTop:"-5px"}}
                      InputLabelProps={{
                        style: { ...textFieldStyles },
                        // shrink: true 
                      }}
                      label="Enter Confirm Password"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {showConfirmPassword ? (
                              <Visibility
                                fontSize="small"
                                onClick={() => setConfirmShowPassword(false)}
                              />
                            ) : (
                              <VisibilityOff
                                fontSize="small"
                                onClick={() => setConfirmShowPassword(true)}
                              />
                            )}
                          </InputAdornment>
                        ),
                      }}
                      onChange={handleConfirmPassErr}
                      onBlur={() => handleBlur("confirmPassword")}
                    />
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      {!showConfirmPasswordErrr &&
                        !confirmPasswordError2 &&
                        password !== confirmPassword &&
                        confirmPassword !== "" && (
                          <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                            <ReportGmailerrorredRoundedIcon sx={{ color: "red", fontSize: "10px" }} />
                            <Typography
                              sx={{ color: "red", fontSize: "8px", }}
                            >
                              Passwords do not match
                            </Typography>
                          </div>
                        )}

                      {confirmPasswordError2 && (
                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                          <ReportGmailerrorredRoundedIcon sx={{ color: "red", fontSize: "10px" }} />
                          <Typography
                            sx={{ color: "red", fontSize: "8px", }}
                          >
                            {confirmPasswordError2}
                          </Typography>
                        </div>
                      )}
                      {showConfirmPasswordErrr && !confirmPasswordError2 && (
                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                          <ReportGmailerrorredRoundedIcon sx={{ color: "red", fontSize: "10px" }} />
                          <Typography
                            sx={{ color: "red", fontSize: "8px", }}
                          >
                            Password needs to match
                          </Typography>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <p className="responsive-p"> Current Curriculums Used</p>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  fullWidth
                  multiline={true}
                  rows={1}
                  sx={{ height: "45px", }}
                  inputProps={{ maxLength: 40 }}
                  InputLabelProps={{
                    style: { ...textFieldStyles },
                    // shrink: true 
                  }}
                  label="Current Curriculum Used"
                  value={curriculum}
                  onChange={(e) => {
                    setCurriculum(e.target.value)
                    setCurriculumError("")
                  }}
                  onBlur={() => handleBlur("curriculum")}
                />
                <div style={{ display: "flex", flexDirection: "row" }}>
                  {curriculumError && (
                    <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                      <ReportGmailerrorredRoundedIcon sx={{ color: "red", fontSize: "10px" }} />
                      <Typography
                        sx={{ color: "red", fontSize: "8px", }}
                      >
                        {curriculumError}
                      </Typography>
                    </div>
                  )}
                </div>

                <p className="responsive-p"> How did you hear about us</p>
                <div style={{ marginBottom: "10px", }}>
                  <FormControl sx={{ width: "100%" }}>
                    <Select
                      MenuProps={MenuProps}
                      multiple
                      displayEmpty
                      input={<OutlinedInput />}
                      inputProps={{ "aria-label": "Without label" }}
                      value={hearSelectedOptions}
                      onChange={handleChange}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return (
                            <p
                              style={{
                                marginBottom: "0px",
                                padding: "2px",
                                fontSize: "10px",
                                fontStyle: "none",
                                fontFamily: "HelveticaNeue-Medium",
                                color: "rgba(167, 167, 167, 0.8)",
                              }}
                            >
                              How did you hear about us
                            </p>
                          );
                        }

                        return selected.join(", ");
                      }}
                      sx={{ height: "40px", }}
                      id="my-select"
                    >
                      {optionsForHearAbtUs.map((name: any) => (
                        <MenuItem key={name} value={name} sx={{ padding: "0px" }}>
                          <Checkbox
                            checked={hearSelectedOptions.indexOf(name) > -1}
                          />
                          <ListItemText
                            primary={name}
                            sx={{ fontFamily: "HelveticaNeue-Medium", fontSize: "12px" }}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </Box>
            </Box>
            <Box
              sx={isPortrait === false ?
                {
                  display: "flex",
                  justifyContent: "center",
                  borderTop: "1px solid #e9e9e9",
                  alignItems: "center",
                  marginTop:"10px",
                  // height:showErrMsg?"8vh":"6vh",
                  // '@media screen and (min-width: 400px) and (max-width:450px)': { 
                  //   height: "40px",
                  //  },
                  //  '@media screen and (min-width: 300px) and (max-width:399px)': { 
                  //   height:"50px",
                  //   marginTop:'3px',
                  //  },

                  //  '@media screen and (min-width: 768px) and (max-width:849px)': { 
                  //   height: "50px",
                  //   marginTop:'7px',
                  //  },
                  //  '@media screen and (min-width: 850px) and (max-width:1026px)': { 
                  //   height:"10px",
                  //   // marginTop:"19px"
                  //  },
                  //  '@media screen and (min-width: 932px) and (max-width:1026px)': { 
                  //   height:"10px",
                  //   // marginTop:"19px"
                  //  },
                  //  '@media screen and (min-width: 1027px) and (max-width:1800px)': { 
                  //   height:"60px",
                  //   marginTop:"2%",
                  //   // marginTop:"19px"
                  //  },
                  '@media screen and (min-width: 850px) and (max-width:1026px)': {
                    height: "30px",
                    marginTop: "30px"
                  },
                } :
                {
                  display: "flex",
                  justifyContent: "center",
                  borderTop: "1px solid #e9e9e9",
                  alignItems: "center",
                  height: "80px",

                  // // height:showErrMsg?"8vh":"6vh",
                  // '@media screen and (min-width: 400px) and (max-width:450px)': { 
                  //   height: "40px",
                  //  },
                  //  '@media screen and (min-width: 300px) and (max-width:399px)': { 
                  //   height:"50px",
                  //   marginTop:'3px',
                  //  },

                  //  '@media screen and (min-width: 768px) and (max-width:849px)': { 
                  //   height: "50px",
                  //   marginTop:'7px',
                  //  },
                  //  '@media screen and (min-width: 850px) and (max-width:1026px)': { 
                  //   height:"70px",
                  //   // marginTop:"19px"
                  //  },
                  //  '@media screen and (min-width: 1027px) and (max-width:1800px)': { 
                  //   height:"60px",
                  //   marginTop:"2%",
                  //   // marginTop:"19px"
                  //  },
                }}
            >
              <Button
                variant="contained"
                style={{
                  // backgroundColor: "#3166ba",
                  color: "#fff",
                }}
                sx={isPortrait === false ? {
                  fontFamily: "HelveticaNeue-Medium",
                  textTransform: "none",
                  width: "350px",
                  height: "40px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  alignSelf: "center",
                  opacity: disabled1 ? 0.6 : 1,
                  // top:"13px",
                  cursor: disabled1 ? "not-allowed" : "pointer",

                  // '@media screen and (max-width: 376px)': { 
                  //   marginBottom:"5px"
                  // },
                  // '@media screen and (min-width: 360px) and (max-width:365px)': { 
                  //   marginLeft:"5px",
                  //   marginRight:"5px",
                  //   // marginTop:"10px"
                  // },
                  // '@media screen and (min-width: 390px) and (max-width:415px)': { 
                  //  marginTop:"10px"
                  // },
                  // '@media screen and (min-width: 430px) and (max-width:435px)': { 
                  //   marginTop:"15px"
                  //  },
                  //  '@media screen and (min-width: 768px) and (max-width:818px)': { 
                  //   marginTop:"10%",
                  //  },
                  //  '@media screen and (min-width: 820px) and (max-width:899px)': { 
                  //   marginTop:"10%",
                  //  },
                  //  '@media screen and (min-width: 900px) and (max-width:1025px)': { 
                  //   marginTop:"6%",
                  //  },
                  //  '@media screen and (min-width: 850px) and (max-width:855px)': { 
                  //   marginTop:"8%",
                  //  },
                  '@media screen and (min-width: 850px) and (max-width:1026px)': {

                    marginTop: "30px"
                  },
                } : {

                  fontFamily: "HelveticaNeue-Medium",
                  textTransform: "none",
                  width: "350px",
                  height: "40px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  alignSelf: "center",
                  opacity: disabled1 ? 0.6 : 1,
                  // top:"13px",
                  cursor: disabled1 ? "not-allowed" : "pointer",

                  // '@media screen and (max-width: 376px)': { 
                  //   marginBottom:"5px"
                  // },
                  // '@media screen and (min-width: 360px) and (max-width:365px)': { 
                  //   marginLeft:"5px",
                  //   marginRight:"5px",
                  //   // marginTop:"10px"
                  // },
                  // '@media screen and (min-width: 390px) and (max-width:415px)': { 
                  //  marginTop:"10px"
                  // },
                  // '@media screen and (min-width: 430px) and (max-width:435px)': { 
                  //   marginTop:"15px"
                  //  },
                  //  '@media screen and (min-width: 768px) and (max-width:818px)': { 
                  //   marginTop:"10%",
                  //  },
                  //  '@media screen and (min-width: 820px) and (max-width:899px)': { 
                  //   marginTop:"10%",
                  //  },
                  //  '@media screen and (min-width: 900px) and (max-width:1025px)': { 
                  //   marginTop:"6%",
                  //  },
                  //  '@media screen and (min-width: 850px) and (max-width:855px)': { 
                  //   marginTop:"8%",
                  //  },


                }}
                // disabled={disabled1}
                onClick={(e: any) => handleSubmit(e)
                  // !disabled &&
                  // 
                }
              >
                {getSignUpUserStatus?.status === "loading" ? "Loading" : "Next"}
              </Button>
            </Box>
          </Box>
        </Modal>
      </div>

      {(openPhoneNumberModal || isOtpFromQueryStr) &&
        <Modal open={openPhoneNumberModal || isOtpFromQueryStr}>
          <Box sx={Phonestyles}>

            <div style={{ display: "flex", justifyContent: "space-between", borderBottom: "1px solid #e9e9e9" }}>

              <div style={{ paddingLeft: "20px", marginTop: "10px", }}>
                <SVGAautiLogo style={{ height: "50px", width: "90px" }} />
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", cursor: "pointer", marginRight: "30px" }} onClick={closeModal}>
                <SVGCloseIcon style={{height: "20px", width: "20px"} }fill="#717a82" />
              </div>
            </div>

            <Box sx={isPortrait === false ? {
              overflowY: "auto",
              '@media screen and (min-width: 681px) and (max-width:900px)': {
                height: "65vh",
              },
              '@media screen and (min-width: 460px) and (max-width:680px)': {
                height: "60vh",
              },
              '@media screen and (min-width: 901px) and (max-width:990px)': {
                height: "65vh",
              },

            } : {}}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img src={mobile} style={{ height: "250px", width: "250px", textAlign: "center", margin: "auto" }} />
              </div>
              <div style={{ justifyContent: 'center', display: "flex", alignItems: 'center', padding: "0px 20px 0px 20px", flexDirection: "column" }}>

                <Box style={{}}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#3166ba",
                      fontFamily: "HelveticaNeue-Medium,Helvetica Neue Medium,Helvetica Neue, sans-serif",
                      fontWeight: "500", textAlign: "center"
                    }}
                  >
                    Almost there!
                  </Typography>
                  <Typography sx={{
                    fontSize: "14px",
                    color: "#3166ba",
                    fontFamily: "HelveticaNeue-Medium,Helvetica Neue Medium,Helvetica Neue, sans-serif",
                    fontWeight: "500", textAlign: "center"
                  }}>Verify your account using OTP (One Time Password)</Typography>
                  <Typography sx={{
                    fontSize: "12px",
                    color: "#272727",
                    fontFamily: "HelveticaNeue-Medium,Helvetica Neue Medium,Helvetica Neue, sans-serif",
                    textAlign: "center"
                  }}>Enter your mobile number below to receive a one time verification code.</Typography>
                </Box>
                <Box
                  sx={{
                    width: "60%", marginTop: "4%",
                    marginBottom: "4%",
                    // height: showErrMsg ? "60%" : "72%",
                  }}
                >
                  <div
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      fontFamily: "HelveticaNeue-Medium",
                      fontSize: "14px",
                    }}
                  >
                    Mobile number
                  </div>
                  <Box style={{ width: '100%' }}>
                    <PhoneInput
                      defaultCountry="US"
                      international
                      placeholder="Enter phone number"
                      value={phNumber}
                      onChange={handlePhoneChange}
                    />
                  </Box>

                </Box>
              </div>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  borderTopWidth: "5px", borderColor: "#f0f2f6", width: "100%"
                }}
              >

                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#3166ba",
                    color: "#fff",
                  }}
                  sx={{

                    fontFamily: "HelveticaNeue-Medium",
                    textTransform: "none",
                    width: "320px",
                    height: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    alignSelf: "center",
                    // opacity: disabled2 ? 0.6 : 1,
                    // cursor: disabled2 ? "not-allowed" : "pointer",




                  }}
                  // disabled={disabled2}
                  onClick={(e: any) =>
                    // !disabled &&
                    openPhoneNumberOtpHandler()
                  }
                >
                  Continue
                </Button>
              </Box>


            </Box>
          </Box>
        </Modal>}

      {phOtp &&
        <Modal open={phOtp}>
          <Box sx={Otpstyles}>
            <div style={{ display: "flex", justifyContent: "space-between", borderBottom: "1px solid #e9e9e9" }}>

              <div style={{ paddingLeft: "20px", marginTop: "10px", }}>
                <SVGAautiLogo style={{ height: "50px", width: "90px" }} />
              </div>

            </div>
            <Box sx={otpcheck}>
              <div style={{ paddingLeft: "10px", marginTop: "10px", }}>
                <ArrowBackIcon fontSize="medium" sx={{ color: "black" }} onClick={closeModelOtp} />
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img src={otpImage} style={{ height: "180px", width: "200px", textAlign: "center", margin: "auto" }} />
              </div>
              <div style={{ justifyContent: 'center', display: "flex", alignItems: 'center', padding: "0px 20px 0px 20px", flexDirection: "column" }}>

                <Box style={{}}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#3166ba",
                      fontFamily: "HelveticaNeue-Medium,Helvetica Neue Medium,Helvetica Neue, sans-serif",
                      fontWeight: "500", textAlign: "center",
                      marginBottom: "5px"
                    }}
                  >
                    Verification Code
                  </Typography>

                  <Typography sx={{
                    fontSize: "13px",
                    color: "#272727",
                    fontFamily: "HelveticaNeue-Medium,Helvetica Neue Medium,Helvetica Neue, sans-serif",
                    textAlign: "center"
                  }}>We have sent the verification code to the phone number you provided</Typography>
                </Box>
                <Box
                  sx={{
                    width: "40%",
                    marginBottom: "1%",
                    marginTop: "3%",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    marginLeft: "3%",
                    display: 'flex',
                    flexDirection: "column",
                    // height: showErrMsg ? "60%" : "72%",
                  }}
                >
                  <div
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                      display: "flex",
                      marginLeft: "5px",
                      justifyContent: "flex-start",
                      fontFamily: "HelveticaNeue-Medium",
                      fontSize: "12px",
                    }}
                  >
                    OTP
                  </div>

                  <OtpInput
                    value={otp}
                    onChange={handleOtpChange}
                    numInputs={4}
                    shouldAutoFocus
                    renderSeparator={<span> {" "}</span>}
                    renderInput={(props) => <input {...props} />}
                    containerStyle={{ display: "flex", justifyContent: "center" }}
                    inputStyle={{
                      width: "40px", // Adjust the width of each input field
                      height: "40px", // Adjust the height of each input field
                      margin: "0 5px", // Adjust the margin to add separation between input fields
                      fontSize: "16px", // Adjust the font size of the input fields
                      textAlign: "center", // Center align the text inside the input fields
                      border: "1px solid #ced4da", // Add border for each input field
                    }}

                  />



                </Box>
                {showOtpError && <Typography sx={{
                  fontSize: "13px",
                  color: "red",
                  fontFamily: "HelveticaNeue-Medium,Helvetica Neue Medium,Helvetica Neue, sans-serif",
                  textAlign: "center"
                }}>{showOtpError}

                </Typography>}
                <Typography sx={{
                  fontSize: "13px",
                  color: "#272727",
                  fontFamily: "HelveticaNeue-Medium,Helvetica Neue Medium,Helvetica Neue, sans-serif",
                  textAlign: "center"
                }}>Didn’t receive the verification OTP?

                </Typography>
                <Link
                  component="button"
                  variant="body2"
                  onClick={openPhoneNumberOtpHandler}>
                  <Typography sx={{
                    fontSize: "13px",
                    color: "#3166ba",
                    fontFamily: "HelveticaNeue-Medium,Helvetica Neue Medium,Helvetica Neue, sans-serif",
                    textAlign: "center"
                  }}>Resend Code</Typography>
                </Link>

              </div>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                borderTop: "1px solid #e9e9e9",
                marginTop: "4%",
                alignContent: 'center',
                height: "15%",
                // '@media screen and (min-width: 400px) and (max-width:450px)': {
                //   height: "95px",
                // },
                // '@media screen and (min-width: 768px) and (max-width:820px)': {
                //   height: "10px",
                // },


              }}
            >
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#3166ba",
                  color: "#fff",
                }}
                sx={{

                  fontFamily: "HelveticaNeue-Medium",
                  textTransform: "none",
                  width: "350px",
                  height: "50px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  alignSelf: "center",
                  top: "3%",
                  opacity: (disabled2 || isOtpSending) ? 0.6 : 1,
                  cursor: (disabled2 || isOtpSending) ? "not-allowed" : "pointer",
                }}
                disabled={disabled2 || isOtpSending}
                onClick={(e: any) => handleSubmits(e)
                  // !disabled &&
                  // handleSubmit(e)
                }
              >
                {isOtpSending ? "Submitting" : "Submit"}
              </Button>
            </Box>

          </Box>
        </Modal>}

      {checkEmail &&
        <Modal open={checkEmail}>
          <Box sx={[checkEmailStyles,{height:"auto"}]}>
            <div style={{ display: "flex", justifyContent: "space-between", borderBottom: "1px solid #e9e9e9" }}>
              <div style={{ paddingLeft: "20px", marginTop: "10px", }}>
                <SVGAautiLogo style={{ height: "50px", width: "90px" }} />
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", cursor: "pointer", marginRight: "15px" }} onClick={closeModal}>
                <SVGCloseIcon style={{ height: "17px", width: "17px" }} fill="#717a82" />
              </div>
            </div>
            <Box sx={[gmailchek,{}]}>
            <Box sx={{ display: 'flex', justifyContent: "center", backgroundColor: "#fff", marginTop: "10px" }}>
              <img src={reviewing} style={{ height: "250px", width: "250px", textAlign: "center", margin: "auto" }} />
            </Box>

            <Box style={{ marginTop: "30px" ,marginBottom:checkEmail?"40px":"0px"}}>
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "#3166ba",
                  fontFamily: "HelveticaNeue-Medium,Helvetica Neue Medium,Helvetica Neue, sans-serif",
                  fontWeight: "500", textAlign: "center"
                }}
              >
                We’re reviewing your request.
              </Typography>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: 'center', alignItems: "center" }}>
                <Typography sx={{
                  fontSize: "14px",
                  color: "#3166ba",
                  fontFamily: "HelveticaNeue-Medium,Helvetica Neue Medium,Helvetica Neue, sans-serif",
                  fontWeight: "500", textAlign: "center"
                }}>Expect an email soon to </Typography>
                <Typography sx={{ fontsize: "14px", fontweight: "500", color: "#333333", marginLeft: "5px" }}>{schoolEmail}</Typography>
              </div>

              <Typography sx={{
                fontSize: "14px",
                color: "#3166ba",
                fontFamily: "HelveticaNeue-Medium,Helvetica Neue Medium,Helvetica Neue, sans-serif",
                textAlign: "center"
              }}>with next steps to access Aauti School.</Typography>
            </Box>
            </Box>
          </Box>
        </Modal>}
    </div>
  );
}

export default SingleTeacherSignupModal;
