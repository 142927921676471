
import React, { useState, useEffect } from 'react';
import { saveItem, getItem } from './storage';

interface IAppContext {
    teacherUserDetails: any,
    setTeacherUserDetails: Function,
    studentUserDetails: any,
    setStudentUserDetails: Function,
    parentUserDetails: any,
    setParentUserDetails: Function
}

const AppContext = React.createContext<IAppContext | null>(null);

export const AppConsumer = AppContext.Consumer;

export const AppProvider = ({ children }: any): any => {

    const [teacherUserDetails, setTeacherUserDetails] = useState(getItem("teacherUserDetails"));
    const [studentUserDetails, setStudentUserDetails] = useState(getItem("studentUserDetails"));
    const [parentUserDetails, setParentUserDetails] = useState(getItem("parentUserDetails"));


    return <AppContext.Provider
        value={{
            teacherUserDetails,
            setTeacherUserDetails: (userDetails: any,key: any) => {
                saveItem(key, userDetails);
                setTeacherUserDetails(userDetails);
            },
            studentUserDetails,
            setStudentUserDetails: (userDetails: any,key: any) => {
                saveItem(key, userDetails);
                setStudentUserDetails(userDetails);
            },
            parentUserDetails,
            setParentUserDetails: (userDetails: any,key: any) => {
                saveItem(key, userDetails);
                setParentUserDetails(userDetails);
            },
        }}>
        {children}
    </AppContext.Provider>

}

export default AppContext;