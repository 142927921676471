export const saveItem = (key: string, value: string) => localStorage.setItem(key, JSON.stringify(value));

export const getItem = (key: string): any => {

    const localStorageItem: string | null = localStorage.getItem(key);

    if (localStorageItem) {
        return JSON.parse(localStorageItem);
    }
    return {}
}

export const clearStorage = () => {
    localStorage.clear();
    // TODO: only clear values which are set through this portal.so that when we login with multiple portal 
    //it wont clear the other portal values
}
