// mixpanel.config.ts
export const MixpanelEvents = {
    PAGE_VIEW: 'Page View',
    LOGIN: 'Login',
    LOGIN_CLASSCODE: 'Login with class Code',
    SIGNUP: 'SignUp',
    BULK_EMAIL: 'Bulk Email'
};
  
export const MixpanelProperties = {
    PAGE_URL: 'Page URL',
};

export const getMixpanelDefaultProperties = () => {
    return {
        userId: localStorage.getItem("userId"),
        product: "AAUTI_SCHOOL Main UI"
    }
}