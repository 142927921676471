
export default function Loading() {

    return(
    
        <div className="w-100 d-flex justify-content-center align-items-center" 
          style={{
            backgroundColor:"rgba(250,250,250,0.6)",position:"absolute",height:"70vh",zIndex:1
          }}
        >
          <div className="spinner-border text-primary ">
          </div>
        </div>
    )
        
}