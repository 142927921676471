// mixpanel.service.ts
import mixpanel from 'mixpanel-browser';
import { MIX_PANEL_TOKEN } from '../config/config';
import { getMixpanelDefaultProperties } from '../config/mixpanel.config';

class MixpanelService {

  constructor() {
    // Initialize Mixpanel with your token
    mixpanel.init(MIX_PANEL_TOKEN, {debug: true, track_pageview: true, persistence: 'localStorage'});
  }

  track(event: string, properties?: any) {

    // Track event using Mixpanel
    mixpanel.track(event, {...properties, ...getMixpanelDefaultProperties()});
  }
}

export default new MixpanelService();