import { getEnvironmentUrl } from "../config/config";

export class BaseService {
  public getHeadersBasedOnType(url: string): any {
    if (url && url.includes("auth")) {
      return {
        "Content-Type": "application/json",
      };
    }
  }

  public async postData(url: string, data: any): Promise<any> {
    const API_URL = getEnvironmentUrl(url);
    let endPoint = url
    if(window.location?.hostname?.includes('prod')) {
      endPoint+="?env=prod"
    } else {
      endPoint+="?env=dev"
    }

    const response = await fetch(`${API_URL}${endPoint}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return await response.json();
  }

  public async getData(url: string): Promise<any> {
    const API_URL = getEnvironmentUrl(url);
    let endPoint = url

    const response = await fetch(`${API_URL}${endPoint}`, {
      method: "GET",
      headers: this.getHeadersBasedOnType(url)
    });
    return await response.json();
  }

  public async postDataWithHeaders(url: string, accessToken: string): Promise<any> {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    })
    return await response.json()
  }

  public async getCleverAccessTokenApi(url: string, data: any): Promise<any> {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Basic ${data.encodedString}`
      },
      body: JSON.stringify(data.body)
    })
    return await response.json()
  }

  public async postDataWithTestUrl(url: string, data: any): Promise<any> {
    const parentUserDetails:any = localStorage.getItem("parentUserDetails")
    const districtId = await JSON.parse(parentUserDetails)?.districtId
    
    const response = await fetch(`${url}districtId=${districtId}`, {
      method: "POST",
      body: JSON.stringify(data),
      headers:{
        'Content-Type':'application/json'
      }
    });
    return (await response) ? response.json() : "";
  }

  // public async getDataTemp(url: string) {
  //   const response = await fetch(`${url}`, { method: "GET" });
  //   return await response.json();
  // }

  // public async tempCheckingUrl(url:any,details:any): Promise<any> {
  //   console.log("ttttt",details,url)

  //   const response = await fetch(`${url}`, {
  //     method: "POST",
  //     body: JSON.stringify(details),
  //   });
  //   // console.log("resssss",response)
  //   return await response.json();
  // }
}
