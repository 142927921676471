import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import bgImage from "../assets/images/login_back_cover.png";
import { useTheme, useMediaQuery, createTheme } from '@mui/material';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 600,
      sm: 760,
      md: 1000,
      lg: 1200,
      xl: 1536,
    },
  },
});

interface Children {
  children: any;
}

function MainLayout({ children }: Children) {
  // const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'))
  const isLargeScreen=useMediaQuery(theme.breakpoints.down('lg'))
  const inBetweenDevices=useMediaQuery(theme.breakpoints.between('sm','md',))

  return (
    <div
      style={{
        boxShadow:"inset 0 35vh 0 0 rgb(245, 245, 200,0.1)",
        backgroundImage: `url(${bgImage})`,
        backgroundSize: "cover",
        height: "100vh",
      }}
    >
      <Container maxWidth="lg" sx={{ 
        paddingTop:inBetweenDevices ?'15%': 8,
        
        }}>
        <Box
          component="div"
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {children}
        </Box>
      </Container>
    </div>
  );
}

export default MainLayout;
