import { BaseService } from "./base.service";
import { environment } from "../config/config";

export class SignUpService extends BaseService {
  public async userSignUp(data: any) {
    const url = "/async-svc/teacher-signup";
    return await this.postData(url, data)
  }
  public async userPhoneValidate(data: any) {
    const url = "/async-svc/teacher-signup/send/otp";
    return await this.postData(url, data)
  }

  public async updateContactStatus(data: any) {
    const url = "/async-svc/event-signup/hubspotcontactdetails";
    return await this.postData(url, data)
  }

  public async saveUserActivity(data: any) {
    const url = `/core-svc/user/teacher/save/user/activities?`;
    return await this.postData(url, data)
  }

  public async lastLoginTime(data: any) {
    const url = `/core-svc/user/teacher/last/logintime?`;
    return await this.postData(url, data)
  }

  public async apiSaveContactNotes(data: any) {
    const url = `/async-svc/event-signup/hubspotNotes`;
    return await this.postData(url, data)
  }

  public async getUserActivityDetails(data: any){
    const url = `/core-svc/user/teacher/get/teacher/useractivity?`
    return await this.postData(url, data)
  }


  public async createContact(data: any) {
    const url = `/content-svc/hubspot/create/contact?`;
    return await this.postData(url, data)
  }

  public async userVerified(data: any) {
    const url = `/core-svc/user/teacher/update/userVerification/status?`;
    return await this.postData(url, data)
  }

  public async userPhoneOtpValidate(data: any) {
    const url = "/async-svc/teacher-signup/verify/otp";
    return await this.postData(url, data)
  }

  public async checkEmailValidation(data:any) {
    const url = `/core-svc/email/send`
    return await this.postDataWithHeaders(url,data)
  }


  public async emailValidation(verificationCode:any) {
    const url = `/async-svc/teacher-signup/validate/${verificationCode.validationCode}`
    return await this.getData(url)
  }

  public async checkUserEmail(data:any){
    const url="/async-svc/teacher-signup/forgotpassword/link"
    return await this.postData(url,data)
  }

  public async forgotPasswordValidation(verificationCode:any) {
    console.log(verificationCode,"validationCodecodee")
    const url = `/async-svc/teacher-signup/forgotpasswordValidate/${verificationCode.validationCode}`
    return await this.getData(url)
  }

  public async updateForgotPassword(data:any){
    const url="/async-svc/teacher-signup/forgotpassword/update"
    return await this.postData(url,data)
  }
}