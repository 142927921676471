import React from "react";
import { Box, Button, Modal, Typography } from "@mui/material";
import './index.css'
const modalStyle = {
    position: 'absolute',
    // marginTop: 8,
    top:"28%",
    left: '50%',
    transform: 'translate( -50%,0)',
    width: '100%',
    maxWidth: '620px',
    // maxWidth: '422px',
    bgcolor: 'background.paper',
    borderRadius: '4px',
    boxShadow: 24,
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    // display: "flex", 
    // flexDirection: "column"
  };

const MailValidationModal=(props:any)=>{
    const {setShowValidationModal,showValidationModal}=props

    const onClickBtn=()=>{
        setShowValidationModal(false)
    }

    return (
   <Modal  open={showValidationModal}>
         <Box  sx={[modalStyle,{height: "40vh"}]}>
         <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", textAlign:"center",flexDirection:"column",p: 5 }}
          className="authentication-modal"
         >
       <img src="https://img.freepik.com/premium-vector/opened-envelope-document-with-green-check-mark-line-icon-official-confirmation-message-mail-sent-successfully-email-delivery-verification-email-flat-design-vector_662353-720.jpg" style={{height:"150px",width:"150px",textAlign:"center"}}/>
{/* <Typography  sx={{ fontSize: 20, alignSelf: "center", textAlign: "center",fontWeight:"bold" }}>Success</Typography> */}
       <Typography paragraph
        mt={1}
        sx={{ fontSize: 16, alignSelf: "center", textAlign: "center",  }}>
         Authentication code is sent to your mail. Please verify the link.
       </Typography>
            <Button variant="contained" style={{ color: "#fff", fontFamily: "sans-serif", textTransform: "none", backgroundColor: "#3166ba", width: "90px", height: "30px",display:"flex",justifyContent:"center",alignItems:"center",alignSelf:"center" ,cursor:"pointer",paddingBottom:"5px"}} onClick={onClickBtn}>OK</Button>
        </Box>
         </Box>
    </Modal>
    )
}

export default MailValidationModal