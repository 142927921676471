import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { LoginService } from "../../../services/login.service";
import { SignUpService } from "../../../services/signup.service";
import MixpanelService from '../../../services/mixpanel.service'
import {MixpanelEvents} from '../../../config/mixpanel.config'
interface UsersState {
  userProfile: any;
  status: "idle" | "loading" | "succeeded" | "failed" | "logout";
  error: any;
  sendChangePasswordErrorDetails: any;
  userGoogleData: any;
  cleverLoginData: any;
  signUpData:any;
  sendOtpData:any;
  validateOtpData:any;
  emailValidationObj:any;
  accessMailValidationObj:any;
  updateForgotPasswordDetails:any;
  forgotPasswordValidationObj:any;
  deviceOrientation:any
  saveUserActivities:any;
  userVerfiedStatus:any;
  saveContactInHubspot:any;
  userActivityDetails:any;
  saveHubspotNotes:any,
  hubspotContactStatus:any,
  lastLoginTime:any
}

const initialState: UsersState = {
  userProfile: {},
  status: "idle",
  error: null,
  sendChangePasswordErrorDetails: {
    status: "",
    data: {},
    error: null,
  },
  userGoogleData: {
    status: "idle",
    data: {},
    error: null,
  },
  cleverLoginData: {
    status: "idle",
    data: {},
    error: null,
  },
  sendOtpData:{
    status: "idle",
    data: {},
    error: null,
  },
  validateOtpData:{
    status: "idle",
    data: {},
    error: null,
  },
  signUpData:{
    status: "idle",
    data: {},
    error: null,
  },
  emailValidationObj:{
    status: "idle",
    data: {},
    error: null
  },
  accessMailValidationObj:{
    status: "idle",
    data: {},
    error: null
  },
  updateForgotPasswordDetails:{
    status: "idle",
    data: {},
    error: null
  },
  forgotPasswordValidationObj:{
    status: "idle",
    data: {},
    error: null
  },
  saveUserActivities:{
    status: "idle",
    data: {},
    error: null
  },
  userVerfiedStatus:{
    status: "idle",
    data: {},
    error: null
  },
  saveContactInHubspot:{
    status: "idle",
    data: {},
    error: null
  },
  saveHubspotNotes:{
    status: "idle",
    data: {},
    error: null
  },
  deviceOrientation: {},
  userActivityDetails:{
    status: "idle",
    data: {},
    error: null
  },
  hubspotContactStatus:{
    status: "idle",
    data: {},
    error: null
  },
  lastLoginTime:{
    status: "idle",
    data: {},
    error: null
  },

} as UsersState;

export const apiUserLogin = createAsyncThunk(
  "user/clearuserprofile",
  async (data: any) => {
    const loginService = new LoginService();
    const response = await loginService.userLogin(data);
    MixpanelService.track(MixpanelEvents.LOGIN,{userEmail:data?.email,response})
    console.log(response, "response");
    return response;
  }
);

export const apiUserSignUp= createAsyncThunk(
  "user/signup",
  async (data: any) => {
    const loginService = new SignUpService();
    const response = await loginService.userSignUp(data);
    console.log(response, "response");
    MixpanelService.track(MixpanelEvents.SIGNUP,{signUpData:{email:data?.email,firstName:data?.firstName,lastName:data?.lastName},response})
    return response;
  }
);

export const apiUserPhoneValidate= createAsyncThunk(
  "send/otp",
  async (data: any) => {
    const loginService = new SignUpService();
    const response = await loginService.userPhoneValidate(data);
    return response;
  }
);

export const apiSaveUserActivity= createAsyncThunk(
  "save/user-activity",
  async (data: any) => {
    const loginService = new SignUpService();
    const response = await loginService.saveUserActivity(data);
    return response;
  }
);

export const apiUpdateLastLoginTime= createAsyncThunk(
  "last/login-time",
  async (data: any) => {
    const loginService = new SignUpService();
    const response = await loginService.lastLoginTime(data);
    return response;
  }
);

export const apiUpdateContactStatus= createAsyncThunk(
  "hubspot/update/contact/status",
  async (data: any) => {
    const loginService = new SignUpService();
    const response = await loginService.updateContactStatus(data);
    return response;
  }
);

export const apiSaveContactNotes= createAsyncThunk(
  "save/contact/notes",
  async (data: any) => {
    const loginService = new SignUpService();
    const response = await loginService.apiSaveContactNotes(data);
    return response;
  }
);

export const apiGetUserActivityDetails = createAsyncThunk("get/useractivity",
  async (data: any) => {
    const loginService = new SignUpService();
    const response = await loginService.getUserActivityDetails(data);
    return response
  })


export const apiCreateContactInHubspot= createAsyncThunk(
  "create/contact",
  async (data: any) => {
    const loginService = new SignUpService();
    const response = await loginService.createContact(data);
    return response;
  }
);


export const apiUserPhoneOtpValidate= createAsyncThunk(
  "verify/otp",
  async (data: any) => {
    const loginService = new SignUpService();
    const response = await loginService.userPhoneOtpValidate(data);
    return response;
  }
);


export const apiUserVerified= createAsyncThunk(
  "user/Verified",
  async (data: any) => {
    const loginService = new SignUpService();
    const response = await loginService.userVerified(data);
    return response;
  }
);

export const checkUserEmail = createAsyncThunk(
  "user/forgotpassword",
  async (data: any) => {
    const userService = new SignUpService();
    const response = await userService.checkUserEmail(data);
    console.log(response, "responseee");
    return response;
  }
);

export const getUserGoogleData = createAsyncThunk(
  "user/googledata",
  async (accessToken: any) => {
    const userService = new LoginService();
    const response = await userService.getUserGoogleData(accessToken);
    return response;
  }
);

export const loginWithCleverData = createAsyncThunk(
  "user/cleveraccesstoken",
  async (data: any) => {
    const userService = new LoginService();
    const response = await userService.loginWithCleverData(data);
    return response;
  }
);

export const apiCheckEmailValidation = createAsyncThunk(
  "user/emailValidation",
  async (data: any) => {
    const userService = new SignUpService();
    console.log("datainslicee",data)
    const response = await userService.checkEmailValidation(data);
    return response;
  }
);

export const getUserEmailFromEmailUuid = createAsyncThunk(
  "user/getEmail",
  async (accessToken: any) => {
    const userService = new LoginService();
    const response = await userService.getUserEmail(accessToken);
    // console.log(response, accessToken, "response_data");
    if(response?.result?.email){
      MixpanelService.track(MixpanelEvents.BULK_EMAIL, {
        email: response?.result?.email,
      });
    }
    return response;
  }
);

export const accessEmailValidation = createAsyncThunk(
  "user/accessEmailValidation",
  async (data: any) => {
    const userService = new SignUpService();
    const response = await userService.emailValidation(data);
    return response;
  }
);

export const apiForgotPasswordValidation = createAsyncThunk(
  "user/accessForgotPasswordValidation",
  async (data: any) => {
    const userService = new SignUpService();
    console.log("datainslicee",data)
    const response = await userService.forgotPasswordValidation(data);
    console.log(response,"respodsee")
    return response;
  }
);

export const updatedPasswordDetails = createAsyncThunk(
  "user/forgotPasswordUpdate",
  async (data: any) => {
    const userService = new SignUpService();
    console.log("datainslicee",data)
    const response = await userService.updateForgotPassword(data);
    console.log(response,"respodsee")
    return response;
  }
);

export const userSlice: any = createSlice({
  name: "user",
  initialState,
  reducers: {
    // clearUserProfile(state, action) {
    //   state.userProfile = {};
    // },
    setStatusIdle: (state) => {
      state.signUpData.status = 'idle';
      state.signUpData.error = null
  },
  clearSignUpData:(state)=>{
    state.accessMailValidationObj.status = 'idle'
  },
  setUpdateForgotPasswordStatusIdle:(state)=>{
    state.updateForgotPasswordDetails.status="idle"
  },
  setValidateForgotPasswordStatusIdle:(state)=>{
    state.forgotPasswordValidationObj.status = "idle"
  },
  setDeviceOrientation: (state, action) => {
    state.deviceOrientation.data = action.payload
  }
  },
  extraReducers: (builder) =>
    builder
      .addCase(apiUserLogin.pending, (state) => {
        state.status = "loading";
      })
      .addCase(apiUserLogin.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload.statusCode === 200) {
          state.status = "succeeded";
          state.userProfile = {
            ...action.payload.result,
            token: action.payload.token,
          };
        } else {
          console.log("in the full filled");
          state.status = "failed";
          state.error =
            "The email or password you entered doesn't match our records.Please double-check and try again.";
        }
      })
      .addCase(apiUserSignUp.pending, (state) => {
        state.signUpData.status = "loading";
      })
      .addCase(apiUserSignUp.fulfilled, (state, action: PayloadAction<any>) => {
        console.log(action,"actionnn")
        if (action.payload.status === 201) {
          state.signUpData.status = "succeeded";
          state.signUpData.data = action?.payload 
          state.signUpData.error = null

        } else if(action.payload.status === 500){
          console.log("failedd")
          state.signUpData.status = "failed";
          state.signUpData.error = action?.payload?.errorMessage 
        }
        // else {
        //   console.log("in the full filled");
        //   state.status = "failed";
        //   state.error =
        //     "The password you entered doesn't match our records.Please double-check and try again.";
        // }
      })
      .addCase(apiUserSignUp.rejected, (state, action: any) => {
        state.signUpData.status = "failed";
        state.signUpData.error = action?.error?.message;
      })
      .addCase(apiUserPhoneValidate.pending, (state) => {
        state.sendOtpData.status = "loading";
      })
      .addCase(apiUserPhoneValidate.fulfilled, (state, action: PayloadAction<any>) => {
        console.log(action,"actionnn")
        if (action.payload.status === 201) {
          state.sendOtpData.status = "succeeded";
          state.sendOtpData.data = action?.payload 
          state.sendOtpData.error = null

        } else if(action.payload.status === 500){
          console.log("failedd")
          state.sendOtpData.status = "failed";
          state.sendOtpData.error = action?.payload?.errorMessage 
        }
      })
      .addCase(apiUserPhoneValidate.rejected, (state, action: any) => {
        state.sendOtpData.status = "failed";
        state.sendOtpData.error = action?.error?.message;
      })
      .addCase(apiUserPhoneOtpValidate.pending, (state) => {
        state.validateOtpData.status = "loading";
      })
      .addCase(apiUserPhoneOtpValidate.fulfilled, (state, action: PayloadAction<any>) => {
        console.log(action,"actionnn")
        if (action.payload.status === 201) {
          state.validateOtpData.status = "succeeded";
          state.validateOtpData.data = action?.payload 
          state.validateOtpData.error = null

        } else if(action.payload.status === 500){
          console.log("failedd")
          state.validateOtpData.status = "failed";
          state.validateOtpData.error = action?.payload?.errorMessage 
        }
      })
      .addCase(apiUserPhoneOtpValidate.rejected, (state, action: any) => {
        state.validateOtpData.status = "failed";
        state.validateOtpData.error = action?.error?.message;
      })
      .addCase(checkUserEmail.pending, (state, action: PayloadAction<any>) => {
        state.sendChangePasswordErrorDetails.status = "loading";
      })
      .addCase(
        checkUserEmail.fulfilled,
        (state, action: PayloadAction<any>) => {
          console.log(action.payload,"actipnpayloaddd")
          if (action.payload.status === 201) {
            state.sendChangePasswordErrorDetails.status = "succeeded";
            state.sendChangePasswordErrorDetails.data = action.payload.result;
            state.sendChangePasswordErrorDetails.error = null;
          } else {
            state.sendChangePasswordErrorDetails.status = "failed";
            state.sendChangePasswordErrorDetails.error = action.payload.errorMessage;
          }
        }
      )
      .addCase(checkUserEmail.rejected, (state, action: any) => {
        state.sendChangePasswordErrorDetails.status = "failed";
        state.sendChangePasswordErrorDetails.error = action.error.message;
      })
      .addCase(apiForgotPasswordValidation.pending, (state, action: PayloadAction<any>) => {
        state.forgotPasswordValidationObj.status = "loading";
      })
      .addCase(
        apiForgotPasswordValidation.fulfilled,
        (state, action: PayloadAction<any>) => {
          console.log(action.payload,"actipnpayloaddd")
          if (action.payload.status === 201) {
            state.forgotPasswordValidationObj.status = "succeeded";
            state.forgotPasswordValidationObj.data = action.payload;
            state.forgotPasswordValidationObj.error = null;
          }else if(action.payload.status === 500){
            state.forgotPasswordValidationObj.status = "failed";
            state.forgotPasswordValidationObj.error = action.payload.errorMessage;
          }
        }
      )
      .addCase(apiForgotPasswordValidation.rejected, (state, action: any) => {
        state.forgotPasswordValidationObj.status = "failed";
        state.forgotPasswordValidationObj.error = action.error.message;
      })
      .addCase(getUserGoogleData.pending, (state) => {
        state.userGoogleData.status = "loading";
      })
      .addCase(
        getUserGoogleData.fulfilled,
        (state, action: PayloadAction<any>) => {
          if (action?.payload?.email) {
            state.userGoogleData.status = "succeeded";
            state.userGoogleData.data = action.payload;
          } else {
            state.userGoogleData.status = "failed";
            state.userGoogleData.error = "Invalid credentials";
          }
        }
      )
      .addCase(getUserGoogleData.rejected, (state, action: any) => {
        state.userGoogleData.status = "failed";
        state.userGoogleData.error = action.error.message;
      })

      .addCase(loginWithCleverData.pending, (state) => {
        state.cleverLoginData.status = "loading";
      })
      .addCase(
        loginWithCleverData.fulfilled,
        (state, action: PayloadAction<any>) => {
          // console.log('cleverr', action.payload)
          if (action?.payload?.result?.email) {
            state.cleverLoginData.status = "succeeded";
            state.cleverLoginData.data = action.payload.result;
          } else {
            state.cleverLoginData.status = "failed";
            let error = action.payload.error_description
            if(!error) {
              error =  "The email you entered doesn't match our records.Please double-check and try again.";
            }
            state.cleverLoginData.error = error;
          }
        }
      )
      .addCase(loginWithCleverData.rejected, (state, action: any) => {
        state.cleverLoginData.status = "failed";
        state.cleverLoginData.error = action.error.message;
      })

      .addCase(apiCheckEmailValidation.pending, (state, action: PayloadAction<any>) => {
        state.emailValidationObj.status = "loading";
      })
      .addCase(
        apiCheckEmailValidation.fulfilled,
        (state, action: PayloadAction<any>) => {
          console.log(action.payload,"actionaplll")
          // if (action.payload.statusCode === 201) {
            state.emailValidationObj.status = "succeeded";
            console.log(action.payload,"actionpayloadd")
            state.emailValidationObj.data =
              action.payload.result.message;
          }
        // }
      )
      .addCase(apiCheckEmailValidation.rejected, (state, action: any) => {
        state.emailValidationObj.status = "failed";
        state.emailValidationObj.error = action.error.message;
      })
      .addCase(accessEmailValidation.pending, (state, action: PayloadAction<any>) => {
        state.accessMailValidationObj.status = "loading";
      })
      .addCase(
        accessEmailValidation.fulfilled,
        (state, action: PayloadAction<any>) => {
          console.log(action.payload,"actionaplll")
          if (action.payload.status === 201) {
            state.accessMailValidationObj.status = "succeeded";
            console.log(action.payload,"actionpayloadd")
            state.accessMailValidationObj.data=action.payload
            state.accessMailValidationObj.error = null
          }else if(action.payload.status === 500){
          state.accessMailValidationObj.status = "failed"
          state.accessMailValidationObj.error = action.payload.errorMessage
         }
        }
      )
      .addCase(accessEmailValidation.rejected, (state, action: any) => {
        state.accessMailValidationObj.status = "failed";
      })
      .addCase(updatedPasswordDetails.pending, (state, action: PayloadAction<any>) => {
        state.updateForgotPasswordDetails.status = "loading";
      })
      .addCase(
        updatedPasswordDetails.fulfilled,
        (state, action: PayloadAction<any>) => {
          console.log(action.payload,"actionaplll")
          if (action.payload.status === 200) {
            state.updateForgotPasswordDetails.status = "succeeded";
            console.log(action.payload,"actionpayloadd")
            state.updateForgotPasswordDetails.data=action.payload.result
            state.updateForgotPasswordDetails.error = null
          }
         else if(action.payload.status === 500){
          state.updateForgotPasswordDetails.status = "failed"
          state.updateForgotPasswordDetails.error = action.payload.errorMessage
         }
        }
      )
      .addCase(updatedPasswordDetails.rejected, (state, action: any) => {
        state.updateForgotPasswordDetails.status = "failed";
      })
      .addCase(apiSaveUserActivity.pending, (state, action: PayloadAction<any>) => {
        state.saveUserActivities.status = "loading";
      })
      .addCase(
        apiSaveUserActivity.fulfilled,
        (state, action: PayloadAction<any>) => {
          if (action.payload.status === 200) {
            state.saveUserActivities.status = "succeeded";
            state.saveUserActivities.data=action.payload.result
            state.saveUserActivities.error = null
          }
         else if(action.payload.status === 500){
          state.saveUserActivities.status = "failed"
          state.saveUserActivities.error = action.payload.errorMessage
         }
        }
      )
      .addCase(apiSaveUserActivity.rejected, (state, action: any) => {
        state.saveUserActivities.status = "failed";
      })
      .addCase(apiUserVerified.pending, (state, action: PayloadAction<any>) => {
        state.userVerfiedStatus.status = "loading";
      })
      .addCase(
        apiUserVerified.fulfilled,
        (state, action: PayloadAction<any>) => {
            state.userVerfiedStatus.status = "succeeded";
            state.userVerfiedStatus.data=action.payload.result
            state.userVerfiedStatus.error = null
         
        }
      )
      .addCase(apiUserVerified.rejected, (state, action: any) => {
        state.userVerfiedStatus.status = "failed";
      })
      .addCase(apiCreateContactInHubspot.pending, (state, action: PayloadAction<any>) => {
        state.saveContactInHubspot.status = "loading";
      })
      .addCase(
        apiCreateContactInHubspot.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.saveContactInHubspot.status = "succeeded";
          state.saveContactInHubspot.data=action.payload.result
          state.saveContactInHubspot.error = null
        
        }
      )
      .addCase(apiCreateContactInHubspot.rejected, (state, action: any) => {
        state.saveContactInHubspot.status = "failed";
      })
      .addCase(apiGetUserActivityDetails.pending, (state, action: PayloadAction<any>) => {
        state.userActivityDetails.status = "loading";
      })
      .addCase(
        apiGetUserActivityDetails.fulfilled,
        (state, action: PayloadAction<any>) => {
         
            state.userActivityDetails.status = "succeeded";
            state.userActivityDetails.data=action.payload.result
            state.userActivityDetails.error = null
        
        }
      )
      .addCase(apiGetUserActivityDetails.rejected, (state, action: any) => {
        state.userActivityDetails.status = "failed";
      })
      .addCase(apiSaveContactNotes.pending, (state, action: PayloadAction<any>) => {
        state.saveHubspotNotes.status = "loading";
      })
      .addCase(
        apiSaveContactNotes.fulfilled,
        (state, action: PayloadAction<any>) => {
         
            state.saveHubspotNotes.status = "succeeded";
            state.saveHubspotNotes.data=action.payload.result
            state.saveHubspotNotes.error = null
        
        }
      )
      .addCase(apiSaveContactNotes.rejected, (state, action: any) => {
        state.saveHubspotNotes.status = "failed";
      })
      .addCase(apiUpdateContactStatus.pending, (state, action: PayloadAction<any>) => {
        state.hubspotContactStatus.status = "loading";
      })
      .addCase(
        apiUpdateContactStatus.fulfilled,
        (state, action: PayloadAction<any>) => {
         
            state.hubspotContactStatus.status = "succeeded";
            state.hubspotContactStatus.data=action.payload.result
            state.hubspotContactStatus.error = null
        
        }
      )
      .addCase(apiUpdateContactStatus.rejected, (state, action: any) => {
        state.hubspotContactStatus.status = "failed";
      })
      .addCase(apiUpdateLastLoginTime.pending, (state, action: PayloadAction<any>) => {
        state.lastLoginTime.status = "loading";
      })
      .addCase(
        apiUpdateLastLoginTime.fulfilled,
        (state, action: PayloadAction<any>) => {
         
            state.lastLoginTime.status = "succeeded";
            state.lastLoginTime.data=action.payload.result
            state.lastLoginTime.error = null
        
        }
      )
      .addCase(apiUpdateLastLoginTime.rejected, (state, action: any) => {
        state.lastLoginTime.status = "failed";
      })
});


export const { setStatusIdle ,clearSignUpData,setUpdateForgotPasswordStatusIdle,setValidateForgotPasswordStatusIdle, setDeviceOrientation} = userSlice.actions

export default userSlice.reducer;
